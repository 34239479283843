import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
} from '@material-ui/core';
import {
  Formik, Form, Field,
} from 'formik';

import { TextField } from 'formik-material-ui';
import {
  history,
} from '../../services';

import {
  changePassword as changePasswordAction,
} from '../../store/auth/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentBody: {
    flexGrow: 0.6,
    display: 'flex',
    alignItems: 'top',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'top',
    },
    padding: '0 100px',
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
    height: '70px',
  },
  signInButton: {
    margin: theme.spacing(2, 0),
    textTransform: 'inherit',
  },
  buttonProgress: {
    color: theme.palette.primary.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: '8px 0',
    position: 'relative',
  },
}));

const ChangePasswordForm = ({ dispatch, showSpinner }) => {
  const classes = useStyles();
  const toHome = () => {
    history.push('/');
  };
  return (

    <div className={classes.contentBody}>
      <Formik
        initialValues={{
          password: '',
          newPassword: '',
          confirmPassword: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.password) {
            errors.password = 'Current password is required';
          }

          if (values.password === values.newPassword) {
            errors.newPassword = 'The new password must be different from the old password.';
          }

          if (!values.newPassword) {
            errors.newPassword = 'New password is required';
          }

          if (!values.confirmPassword) {
            errors.confirmPassword = 'Confirm password is required';
          }

          if (values.confirmPassword !== values.newPassword) {
            errors.confirmPassword = 'Passwords do not match.';
          }

          const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/;
          const reError = 'Requires lowercase letters, uppercase letters, numbers, special characters. Minimum 8 characters, maximum 99 characters. Whitespace (space, tab, carriage return) not allowed.';
          if (!re.test(values.newPassword)) {
            errors.newPassword = reError;
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          const objectChangePassword = {
            password: values.password,
            newPassword: values.newPassword,
          };

          setTimeout(() => {
            dispatch(changePasswordAction(objectChangePassword));
            setSubmitting(false);
          }, 1);
        }}
      >

        {({
          submitForm, isSubmitting, errors, touched,
        }) => (
          <Form style={{ maxWidth: 500 }}>
            <Typography
              className={classes.title}
              variant="h4"
            >
              Change Password
            </Typography>
            <Field
              component={TextField}
              className={classes.textField}
              fullWidth
              label="Current password"
              name="password"
              type="password"
              variant="outlined"
              error={false}
            />
            {/* <ErrorMessage name="username" /> */}
            <Field
              component={TextField}
              className={classes.textField}
              fullWidth
              label="Password"
              name="newPassword"
              type="password"
              variant="outlined"

            />
            <Field
              component={TextField}
              className={classes.textField}
              fullWidth
              label="Confirm password"
              name="confirmPassword"
              type="password"
              variant="outlined"
              error={false}
            />
            <div className={classes.wrapper}>
              <Button
                className={classes.signInButton}
                color="primary"
                fullWidth
                size="large"
                type="button"
                variant="contained"
                disabled={isSubmitting || showSpinner}
                onClick={submitForm}
              >
                Change Password
              </Button>

              {showSpinner && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
            <div className={classes.wrapper}>
              <Button
                className={classes.signInButton}
                color="primary"
                fullWidth
                size="large"
                type="button"
                variant="contained"
                onClick={toHome}
              >
                Go Back
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>

  );
};

ChangePasswordForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  showSpinner: state.auth.showSpinner,
});

export default connect(mapStateToProps)(ChangePasswordForm);
