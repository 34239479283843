const API_URL = 'apiurl';
const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiZ29vb2dpIiwiYSI6ImllWklPR2sifQ.sISXZXuC_xgevrd6H3s7yQ';

export {
  API_URL, MAPBOX_ACCESS_TOKEN,
};

export const elementTypeOptions = [{ id: 'water', name: 'water' }, { id: 'air', name: 'air' }, { id: 'forest', name: 'forest' }, { id: 'ground', name: 'ground' }];
export const levelOptions = [{ id: 1, name: '1' }, { id: 2, name: '2' }, { id: 3, name: '3' }, { id: 4, name: '4' }];
export const centerCicleRadius = 15; // meters, in the center we have cocle with this radius, where shouldn't be any locations
