import { SET_CURRENT_USER_DATA, DELETE_CURRENT_USER_DATA } from './types';

const initialState = {
  groups: [],
  username: null,
};

export default function userReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case SET_CURRENT_USER_DATA:
      return {
        groups: action.payload.groups,
        username: action.payload.username,
      };

    case DELETE_CURRENT_USER_DATA:
      return {
        groups: [],
        username: null,
      };
    default:
      return state;
  }
}
