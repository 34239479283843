/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button, colors,
} from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {

  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: { backgroundColor: 'transparent' },
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,

  },

}));

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
}));

const CustomRouterLink = forwardRef((props, ref) => {
  const classes = useTreeItemStyles();
  const { to } = props;

  if (to === null) {
    return (
      <div className={classes.labelRoot} ref={ref} {...props} />
    );
  }
  return (
    <div
      ref={ref}
      style={{ flexGrow: 1 }}
    >
      <RouterLink {...props} />
    </div>
  );
});

const SidebarNav = (props) => {
  const { pages } = props;

  const classes = useStyles();

  const items = [];

  Object.keys(pages).forEach((key) => {
    if (pages && Object.prototype.hasOwnProperty.call(pages, key)) {
      const page = pages[key];
      items.push(<StyledTreeItem key={page.nodeId} nodeId={page.nodeId} labelText={page.title} labelIcon={page.icon} href={page.href}>
        {page.subpages !== undefined ? page.subpages.map((subPage) => (
          <StyledTreeItem
            key={subPage.nodeId}
            nodeId={subPage.nodeId}
            labelText={subPage.title}
            labelIcon={subPage.icon}
            href={subPage.href}
            color="#a250f5"
            bgColor="#f3e8fd"
          />
        )) : ''}
      </StyledTreeItem>);
    }
  });

  return (
    <TreeView
      className={classes.root}
      defaultExpanded={['3']}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
    >
      {items}
    </TreeView>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
  to: PropTypes.string,
};

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();

  const {
    href, labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other
  } = props;

  return (
    <TreeItem
      label={(
        <div style={{ paddingLeft: 0 }} className={classes.labelRoot}>
          <Button
            style={{ backgroundColor: 'transparent', paddingLeft: 0 }}
            className={classes.button}
            component={CustomRouterLink}
            to={href}
            icon={LabelIcon}
            text={labelText}
          >
            <LabelIcon color="inherit" className={classes.labelIcon} />
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          </Button>
        </div>
      )}
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  href: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

CustomRouterLink.propTypes = {
  icon: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
};

export default SidebarNav;
