import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { SpinnerDotted } from 'spinners-react';
import PropTypes from 'prop-types';

const CoverSpinner = (props) => {
  const { show } = props;

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
      aria-labelledby="simple-dialog-title"
      open={show}
    >
      <SpinnerDotted enabled={show} color="#262626" />
    </Dialog>
  );
};

CoverSpinner.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default connect()(CoverSpinner);
