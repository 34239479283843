import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ReactJson from 'react-json-view';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText,
  Tab, Tabs, AppBar, Box, Grid, Select, MenuItem, FormControl, InputLabel,
  FormControlLabel, Switch,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AddLocation, EditLocation } from '@material-ui/icons';
import { centerCicleRadius } from '../../../constants';
import { removeLocation, showHideMarkerLocation, addLocation } from '../../../store/mapbox/actions';
import * as constants from '../../../constants';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      style={{ flex: 272 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  textField: {
    width: '100%',
    padding: '0 2px',
  },
  tabWidth: {
    minWidth: '50%',
    fontWeight: '900',
  },
  button: {
    width: '100%',
    margin: '8px 0',
  },
  panel: {
    padding: 0,
    '& .MuiBox-root': {
      padding: '24px !important',
    },
  },

  tabHeader: {
    backgroundColor: 'rgb(38, 38, 38, 0.1)',
  },

}));

const TilesInfo = ({
  locationSelected, markerLocation,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [stateAddingLocation, setstateAddingLocation] = useState({});

  const onAddLocation = () => {
    dispatch(addLocation(stateAddingLocation.main, markerLocation.latitude, markerLocation.longitude));
  };

  const handleChangeMain = (e) => {
    setstateAddingLocation({ ...stateAddingLocation, main: e.target.value });
  };

  const onLocationRemove = () => {
    setOpenDialog(true);
  };
  const onShowHideMarker = () => {
    dispatch(showHideMarkerLocation(!markerLocation.show));
    setstateAddingLocation({});
  };

  const [tabOrder, setTabOrder] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabOrder(newValue);
  };

  const handleAgree = () => {
    dispatch(removeLocation(locationSelected.gcs, locationSelected.coordinatesOriginal));
    setOpenDialog(false);
  };
  const handleDisagree = () => {
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.tabHeader} position="static">
        <Tabs
          className={classes.tabPanel}
          value={tabOrder}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="off"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab className={classes.tabWidth} icon={<EditLocation />} {...a11yProps(0)} />
          <Tab className={classes.tabWidth} icon={<AddLocation />} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabOrder} className={classes.panel} index={0}>
        {locationSelected ? (
          <div>
            <ReactJson
              style={{
                border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: 4, padding: 4, marginTop: 5, marginBottom: 5,
              }}
              src={locationSelected}
              iconStyle="square"
              displayDataTypes={false}
              displayObjectSize={false}
              collapsed="1"
            />
            <Button
              variant="contained"
              className={classes.txTilesGeneration}
              style={{ width: '100%', marginBottom: 5 }}
              color="primary"
              onClick={onLocationRemove}
            >
              Remove
            </Button>
          </div>
        ) : (<p>Please select location</p>)}
      </TabPanel>
      <TabPanel value={tabOrder} className={classes.panel} index={1}>
        <Grid container item xs={12} justify="flex-end">
          <FormControlLabel
            labelPlacement="start"
            control={<Switch color="primary" checked={markerLocation.show} onChange={onShowHideMarker} name="state" />}
            label="Show Marker for adding"
          />
        </Grid>
        {markerLocation.show && (
        <Grid container>
          <Grid item xs={12} className={classes.txTilesGeneration} style={{ marginBottom: 5 }}>
            <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
              <InputLabel>Main</InputLabel>
              <Select
                id="select-level"
                value={stateAddingLocation.main || ''}
                onChange={handleChangeMain}
                label="Level"
              >
                {constants.elementTypeOptions.map((elType) => (<MenuItem key={elType.id} value={elType.id}>{elType.name}</MenuItem>)) }
              </Select>
            </FormControl>
          </Grid>
          <Button
            variant="contained"
            className={classes.txTilesGeneration}
            style={{ width: '100%', marginBottom: 5 }}
            color="primary"
            onClick={onAddLocation}
            disabled={!stateAddingLocation.main}
          >
            Add Location
          </Button>
          { markerLocation && !markerLocation.centerCicleValid && (
          <Alert severity="warning" style={{ marginBottom: 5, width: '100%' }}>
            The location is within
            {' '}
            {centerCicleRadius}
            {' '}
            meters radius of the center!
          </Alert>
          )}
        </Grid>
        )}
      </TabPanel>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Location will be removed. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleAgree} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

TilesInfo.propTypes = {
  locationSelected: PropTypes.shape(),
  markerLocation: PropTypes.shape().isRequired,
};

TilesInfo.defaultProps = {
  locationSelected: undefined,
};

const mapStateToProps = (state) => ({
  locationSelected: state.mapbox.locationSelected,
  markerLocation: state.mapbox.markerLocation,
});

export default connect(mapStateToProps)(TilesInfo);
