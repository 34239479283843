import types from './types';

const initialState = {
  selectionArea: {
    state: false,
    counter: 0,
    coordinates: {
      minX: '',
      maxX: '',
      minY: '',
      maxY: '',
    },
  },
  list: [],
  listPage: 0,
  generatedAreas: {
    zoomLoaded: [],
    areas: [],
  },
};

export default function settingsReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case types.UPDATE_SELECTION_AREA: {
      return {
        ...state,
        selectionArea: { ...state.selectionArea, ...action.payload },
      };
    }
    case types.RESET_SELECTION_AREA: {
      return {
        ...state,
        selectionArea: {
          state: false,
          counter: 0,
          coordinates: {
            minX: '',
            maxX: '',
            minY: '',
            maxY: '',
          },
        },
      };
    }
    case types.ADD_POINT_SELECTION_AREA: {
      const { coordinates } = state.selectionArea;
      if (action.payload.x > state.selectionArea.coordinates.maxX) {
        coordinates.minX = coordinates.maxX;
        coordinates.maxX = action.payload.x;
      } else {
        coordinates.minX = action.payload.x;
      }

      if (action.payload.y > state.selectionArea.coordinates.maxY) {
        coordinates.minY = coordinates.maxY;
        coordinates.maxY = action.payload.y;
      } else {
        coordinates.minY = action.payload.y;
      }

      return {
        ...state,
        selectionArea: { ...state.selectionArea, coordinates },
      };
    }
    case types.FETCH_LIST_SUCCESS: {
      return {
        ...state,
        list: action.payload,
      };
    }
    case types.FETCH_AREAS_SUCCESS: {
      const areas = [...state.generatedAreas.areas, ...action.payload.coordinates];
      return {
        ...state,
        generatedAreas: { ...state.generatedAreas, areas },
      };
    }
    case types.ADD_ZOOM_LOADED_GENERATED_AREAS: {
      return {
        ...state,
        generatedAreas: { ...state.generatedAreas, zoomLoaded: [...state.generatedAreas.zoomLoaded, action.payload] },
      };
    }
    case types.FETCH_DELETE_REQUEST: {
      return {
        ...state,
        list: state.list.filter((item) => item.id !== action.payload),
      };
    }
    case types.SET_LIST_PAGE: {
      return {
        ...state,
        listPage: action.payload,
      };
    }
    case types.ADD_TO_LIST: {
      return {
        ...state,
        list: [...state.list, action.payload],
      };
    }
    default:
      return state;
  }
}
