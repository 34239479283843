import React from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  TextField, Grid, Button,
} from '@material-ui/core';

import ReactJson from 'react-json-view';

import {
  setTilesInfo, testTilesInfo
} from '../../../store/mapbox/actions';

import {
  validateLong, validateLat,
} from '../../../helpers/gHelper';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  textField: {
    width: '100%',
    padding: '0 2px',
  },

  button: {
    width: '100%',
    margin: '8px 0',
  },
  txTilesGeneration: {
    marginTop: 5,
    marginBottom: 5,
    width: '100%',
    padding: '0 2px',
  },
}));

const TilesInfo = ({
  tilesInfo,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (e, key) => {
    const { value } = e.target;

    switch (key) {
      case 'latitude':
        dispatch(setTilesInfo({ coordinates: { ...tilesInfo.coordinates, ...{ latitude: value } } }));
        break;
      case 'longitude':
        dispatch(setTilesInfo({ coordinates: { ...tilesInfo.coordinates, ...{ longitude: value } } }));
        break;
      default:
        break;
    }
  };

  const handleTestButton = () => {
    dispatch(testTilesInfo({
      lat: tilesInfo.coordinates.latitude,
      lon: tilesInfo.coordinates.longitude,
    }));
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={6} className={classes.txTilesGeneration} style={{ paddingRight: 2 }}>
          <TextField
            style={{ width: '100%' }}
            id="latitude"
            label="Latitude"
            value={tilesInfo.coordinates.latitude}
            onChange={(e) => { handleChange(e, 'latitude'); }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} className={classes.txTilesGeneration} style={{ paddingLeft: 2 }}>
          <TextField
            style={{ width: '100%' }}
            id="longitude"
            label="Longitude"
            className={classes.fullWidth}
            value={tilesInfo.coordinates.longitude}
            onChange={(e) => { handleChange(e, 'longitude'); }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid container>
          <ReactJson
            style={{
              border: '1px solid rgba(0, 0, 0, 0.23)', width: '100%', borderRadius: 4, padding: 4, marginTop: 5, marginBottom: 5,
            }}
            name={false}
            src={tilesInfo.coordinates}
            iconStyle="square"
            displayDataTypes={false}
            displayObjectSize={false}
            collapsed="1"
            onChange={(e) => { console.log(e); }}
          />
        </Grid>
        <Grid container>
          <Button
            variant="contained"
            className={classes.button}
            style={{ width: '100%' }}
            color="primary"
            onClick={handleTestButton}
            disabled={!validateLong(tilesInfo.coordinates.longitude) || !validateLat(tilesInfo.coordinates.latitude)}
          >
            Test
          </Button>
        </Grid>
        {Object.keys(tilesInfo.result).length !== 0 && tilesInfo.result.constructor !== Object
            && (
              <Grid container>

                <p>Result:</p>
                <ReactJson
                  style={{
                    border: '1px solid rgba(0, 0, 0, 0.23)', width: '100%', borderRadius: 4, padding: 4, marginTop: 5, marginBottom: 5,
                  }}
                  name={false}
                  src={tilesInfo.result}
                  iconStyle="square"
                  displayDataTypes={false}
                  displayObjectSize={false}
                  collapsed="1"
                />
              </Grid>
            )}
      </Grid>
    </div>
  );
};

TilesInfo.propTypes = {
  tilesInfo: PropTypes.shape(),
};

TilesInfo.defaultProps = {
  tilesInfo: {
    coordinates: {
      latitude: '',
      longitude: '',
    },
    result: {},
    status: null,
  },
};

const mapStateToProps = (state) => ({
  tilesInfo: state.mapbox.tilesInfo,
});

export default connect(mapStateToProps)(TilesInfo);
