import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ReactMapGL, {
  Source, Layer, ScaleControl, NavigationControl, Marker,
} from 'react-map-gl';

import { SpinnerRoundFilled } from 'spinners-react';

import { FilterTiltShift } from '@material-ui/icons';
import {
  downloadTilesLatLon, clickOnMap, setSelectedLocation, setSelectedContentLocation, updateLayers,
  showTileDetails, clearTileDetails, setViewport, setMarkerLocationWithValidation, showSelectedArea, setTilesInfo, removeSelectedArea,
  showGenerationTilesAres, drawCircleForMarkerLocation,
} from '../../store/mapbox/actions';
import {
  setSelectionAria, addPointToSelectionAria, setListPage, getGenerationList, getGeneratedAreas,
} from '../../store/generate/actions';

import {
  lon2tile, lat2tile, validateLat, validateLong,
} from '../../helpers/gHelper';
import { MAPBOX_ACCESS_TOKEN } from '../../constants';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  menu: {
    background: '#fff',
    position: 'absolute',
    zIndex: 1,
    top: 16,
    right: 42,
    borderRadius: 3,
    width: 120,
    border: '1px solid rgba(0, 0, 0, 0.4)',
    fontFamily: '\'Open Sans\', sans-serif',
    '& a': {
      fontSize: 13,
      color: '#404040',
      display: 'block',
      margin: 0,
      // padding: 0,
      padding: 10,
      textDecoration: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
      textAlign: 'center',
    },
    '& a::last-child': {
      border: 'none',
    },
    '& a:hover': {
      backgroundColor: '#f8f8f8',
      color: '#404040',
    },
    '& a.active': {
      backgroundColor: '#3887be',
      color: '#ffffff',
    },
    '& a.active:hover': {
      background: '#3074a4',
    },
  },
  popUpContent: {
    '& table, th, td': {
      border: '1px solid black',
      borderCollapse: 'collapse',
    },
    '& th, td': {
      padding: 5,
      textAlign: 'left',
    },
  },
}));

// const layersStateLink = { value: undefined };

const Mapbox = (props) => {
  const {
    listLayersPanel, viewport, markerLocation, isLoading, selectionArea, list, generatedAreas,
  } = props;
  const classes = useStyles();

  const [clickingLayerMenu, setClickingLayerMenu] = useState(false);

  const initialViewportData = {
    latitude: validateLat(viewport.latitude) ? parseFloat(viewport.latitude) : 0,
    longitude: validateLong(viewport.longitude) ? parseFloat(viewport.longitude) : 0,
    zoom: viewport.zoom,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(getGenerationList());
    }, 10000);
    return () => {
      clearInterval(timer);
      dispatch(removeSelectedArea());
      dispatch(setListPage(0));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(showGenerationTilesAres(list));
  }, [list, dispatch]);

  useEffect(() => {
    dispatch(getGeneratedAreas());
  }, [dispatch]);

  const changeVisability = (clickedLayer) => {
    setClickingLayerMenu(true);
    const listLayersPanelNew = [...listLayersPanel];
    const layerGroupIndex = listLayersPanel.findIndex((l) => l.id === clickedLayer);
    listLayersPanelNew[layerGroupIndex].show = !listLayersPanelNew[layerGroupIndex].show;
    listLayersPanelNew[layerGroupIndex].layers = listLayersPanelNew[layerGroupIndex].layers.map((layer) => ({ ...layer, show: listLayersPanelNew[layerGroupIndex].show }));
    dispatch(updateLayers(listLayersPanelNew));
  };

  const markerDragEnd = (event) => {
    dispatch(setMarkerLocationWithValidation(event.lngLat[0], event.lngLat[1]));
  };
  const markerDrag = (event) => {
    dispatch(drawCircleForMarkerLocation(event.lngLat[0], event.lngLat[1]));
  };
  return (
    <div style={{ width: '100%' }}>
      <ReactMapGL
        {...initialViewportData}
        width="100%"
        height="100vh"
        on
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        onViewportChange={async (viewportNew) => {
          dispatch(setViewport({
            latitude: viewportNew.latitude,
            longitude: viewportNew.longitude,
            zoom: viewportNew.zoom,
          }));

          dispatch(downloadTilesLatLon(viewportNew.latitude, viewportNew.longitude, viewportNew.zoom));

          if (viewportNew.zoom > 8 && !generatedAreas.zoomLoaded.includes(12)) {
            dispatch(getGeneratedAreas(12));
          }
        }}
        onClick={async (pointerEvent) => {
          if (clickingLayerMenu) {
            setClickingLayerMenu(false);
            return false;
          }
          if (selectionArea.state) {
            const counter = selectionArea.counter === 0 ? 1 : 0;

            dispatch(addPointToSelectionAria({
              x: lon2tile(pointerEvent.lngLat[0], 17),
              y: lat2tile(pointerEvent.lngLat[1], 17),
            }));
            dispatch(setSelectionAria({ counter, state: counter !== 0 }));
            if (selectionArea.coordinates.minX
              && selectionArea.coordinates.minY
              && selectionArea.coordinates.maxX
              && selectionArea.coordinates.maxY) {
              dispatch(showSelectedArea([
                selectionArea.coordinates.minX,
                selectionArea.coordinates.minY,
                selectionArea.coordinates.maxX,
                selectionArea.coordinates.maxY,
              ]));
            }
            return false;
          }
          dispatch(clickOnMap(pointerEvent.lngLat));

          dispatch(setViewport({
            latitudeTmp: pointerEvent.lngLat[1],
            longitudeTmp: pointerEvent.lngLat[0],
            x: lon2tile(pointerEvent.lngLat[0], 17),
            y: lat2tile(pointerEvent.lngLat[1], 17),
          }));

          dispatch(setTilesInfo({
            coordinates: {
              latitude: pointerEvent.lngLat[1],
              longitude: pointerEvent.lngLat[0],
            },
          }));

          const point = pointerEvent.features.find((f) => f.layer.id.startsWith('points-helsinki-layer'));
          // console.log(JSON.stringify(point));
          if (point !== undefined) {
            const pointData = JSON.parse(point.properties.data);
            dispatch(setSelectedLocation(pointData));
            return true;
          }

          let feature = pointerEvent.features.find((f) => f.layer.id.startsWith('content-fill-helsinki-layer'));
          if (feature !== undefined) {
            dispatch(setSelectedContentLocation(JSON.parse(feature.properties.data)));
            return true;
          }
          feature = pointerEvent.features.find((f) => f.layer.id.startsWith('tiles-helsinki-layer'));

          if (feature !== undefined) {
            const gcs = JSON.parse(feature.properties.gcs);
            return dispatch(showTileDetails(gcs, feature.properties.level, feature.properties.main));
          }
          dispatch(clearTileDetails());
        }}
      >
        {listLayersPanel.map((item) => item.layers.map((layer) => (
          <Source type="geojson" data={layer.source} key={layer.id}>
            {layer.show
              ? (
                <Layer
                  id={layer.id}
                  type={layer.type}
                  paint={layer.paint}
                  layout={layer.layout || {}}
                />
              ) : (
                <div />
              )}
          </Source>
        )))}
        <div style={{ position: 'absolute', left: 20, top: 12 }}>
          <NavigationControl />
          <p style={{
            background: 'white', marginTop: 3, borderRadius: 3, paddingTop: 2, paddingBottom: 2, textAlign: 'center', boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
          }}
          >
            {viewport.zoom.toFixed(1)}
          </p>
        </div>
        <div style={{ position: 'absolute', bottom: 100, left: 20 }}>
          <ScaleControl maxWidth={100} unit="metric" />
        </div>
        <div style={{ position: 'absolute', left: 15, top: 124 }}>
          <SpinnerRoundFilled enabled={isLoading} color="#262626" size="40" />
        </div>
        <nav className={classes.menu}>
          {listLayersPanel.filter((el) => el.inPanel).map((item) => (
            <a key={item.id} href={`#${item.name}`} className={item.show ? 'active' : null} onClick={() => { changeVisability(item.id); }}>
              {' '}
              {item.name}
            </a>
          ))}
        </nav>
        {markerLocation.show
        && (
        <Marker
          onDragEnd={markerDragEnd}
          onDrag={markerDrag}
          longitude={markerLocation.longitude}
          latitude={markerLocation.latitude}
          offsetLeft={-12}
          offsetTop={-12}
          draggable
        >
          <FilterTiltShift style={{ cursor: 'move' }} />
        </Marker>
        )}
      </ReactMapGL>

    </div>
  );
};

const mapStateToProps = (state) => ({
  listLayersPanel: state.mapbox.listLayersPanel,
  viewport: state.mapbox.viewport,
  isLoading: state.mapbox.mapDataLoading.isLoading,
  selectionArea: state.generate.selectionArea,
  list: state.generate.list,
  generatedAreas: state.generate.generatedAreas,
  markerLocation: state.mapbox.markerLocation,
});

Mapbox.propTypes = {
  listLayersPanel: PropTypes.arrayOf(
    PropTypes.shape([]),
  ),
  viewport: PropTypes.shape(),
  isLoading: PropTypes.bool.isRequired,
  selectionArea: PropTypes.shape(),
  list: PropTypes.arrayOf(PropTypes.shape()),
  generatedAreas: PropTypes.shape(),
  markerLocation: PropTypes.shape(),
};

Mapbox.defaultProps = {
  listLayersPanel: null,
  viewport: undefined,
  selectionArea: null,
  list: [],
  generatedAreas: {},
  markerLocation: {},
};

export default connect(mapStateToProps)(Mapbox);
