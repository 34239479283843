import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
} from '@material-ui/core';
import {
  Formik, Form, Field,
} from 'formik';

import { TextField } from 'formik-material-ui';

import {
  login as loginAction, savePassword, saveUsername,
} from '../../store/auth/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentBody: {
    flexGrow: 0.6,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    padding: '0 100px',
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
    height: '70px',
  },
  signInButton: {
    margin: theme.spacing(2, 0),
    textTransform: 'inherit',
  },
  buttonProgress: {
    color: theme.palette.primary.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: '8px 0',
    position: 'relative',
  },
}));

const Login = ({ dispatch, showSpinner }) => {
  const classes = useStyles();
  return (

    <div className={classes.contentBody}>
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.username) {
            errors.username = 'Username is required';
          }

          if (!values.password) {
            errors.password = 'Password is required';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          const objectLogin = {
            username: values.username,
            password: values.password,
            action: 'login',
          };

          dispatch(savePassword(values.password));
          dispatch(saveUsername(values.username));

          setTimeout(() => {
            dispatch(loginAction(objectLogin));
            setSubmitting(false);
          }, 1);
        }}
      >

        {({
          submitForm, isSubmitting, errors, touched,
        }) => (
          <Form>
            <Typography
              className={classes.title}
              variant="h2"
            >
              Sign in
            </Typography>
            <Field
              component={TextField}
              className={classes.textField}
              fullWidth
              label="Username"
              name="username"
              type="text"
              variant="outlined"
              error={false}
            />
            {/* <ErrorMessage name="username" /> */}
            <Field
              component={TextField}
              className={classes.textField}
              fullWidth
              label="Password"
              name="password"
              type="password"
              variant="outlined"

            />
            <div className={classes.wrapper}>
              <Button
                className={classes.signInButton}
                color="primary"
                fullWidth
                size="large"
                type="button"
                variant="contained"
                disabled={isSubmitting || showSpinner}
                onClick={submitForm}
              >
                Login
              </Button>
              {showSpinner && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </Form>
        )}
      </Formik>
    </div>

  );
};

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  showSpinner: state.auth.showSpinner,
});

export default connect(mapStateToProps)(Login);
