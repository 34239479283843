import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  Grid,
} from '@material-ui/core';
import { Login, QrCode } from '../../components';

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  orm: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  grid: {
    height: '100%',
  },
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },

  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
}));

const LoginContainer = ({ action, dispatch }) => {
  const classes = useStyles();

  const displayedForm = action === 'login' ? (<Login />) : (<QrCode />);

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote} />
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          {displayedForm}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  action: state.auth.action,
});

export default connect(mapStateToProps)(LoginContainer);
