import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  TextField, Grid, Button, FormControlLabel, Switch, Dialog, DialogActions, DialogContent, DialogContentText,
  Checkbox,
} from '@material-ui/core';

import { isNumber } from '@turf/turf';
import {
  generate, setSelectionAria, resetSelectionAria,
} from '../../../store/generate/actions';

import {
  removeSelectedArea, showSelectedArea,
} from '../../../store/mapbox/actions';

import {
  validateSelectionArea,
} from '../../../helpers/gHelper';

import List from './List';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  textField: {
    width: '100%',
    padding: '0 2px',
  },

  button: {
    width: '100%',
    margin: '8px 0',
  },
  txTilesGeneration: {
    marginTop: 5,
    marginBottom: 5,
    width: '100%',
    padding: '0 2px',
  },
}));

const TilesGeneration = ({
  selectionArea, list, listPage,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const defaultUpdateConfig = {
    updateLevels: { regenerateAutoGenerated: false, regenerateManualAdded: false },
    updateMainElement: { regenerateAutoGenerated: false, regenerateManualAdded: false },
    updateLocations: { regenerateAutoGenerated: false, regenerateManualAdded: false },
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [updateConfig, setUpdateConfig] = useState(defaultUpdateConfig);
  const handleSelectiondArea = (event) => {
    dispatch(setSelectionAria({ state: event.target.checked, counter: 0 }));
    if (event.target.checked) {
      dispatch(setSelectionAria({
        coordinates: {
          minX: '', maxX: '', minY: '', maxY: '',
        },
      }));
      dispatch(removeSelectedArea());
    }
  };

  const onGenerate = () => {
    dispatch(generate(selectionArea.coordinates.minX,
      selectionArea.coordinates.minY,
      selectionArea.coordinates.maxX,
      selectionArea.coordinates.maxY,
      updateConfig));
    setUpdateConfig(defaultUpdateConfig);
    dispatch(removeSelectedArea());
    dispatch(resetSelectionAria());
  };

  const handleGenerate = () => {
    if ((selectionArea.coordinates.maxX - selectionArea.coordinates.minX) * (selectionArea.coordinates.maxY - selectionArea.coordinates.minY) > 1000) {
      setOpenDialog(true);
    } else {
      onGenerate();
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleAgree = () => {
    onGenerate();
    setOpenDialog(false);
  };
  const handleDisagree = () => {
    setOpenDialog(false);
  };

  const handleChangeUpdateConfig = (e, key) => {
    const selected = e.target.checked;
    console.log(selected);
    switch (key) {
      case 'updateLevels.regenerateAutoGenerated': {
        const updateConfigCopy = {
          ...updateConfig,
          updateLevels: {
            ...updateConfig.updateLevels,
            regenerateAutoGenerated: selected,
          },
        };
        setUpdateConfig(updateConfigCopy);
      }
        break;
      case 'updateLevels.regenerateManualAdded': {
        const updateConfigCopy = {
          ...updateConfig,
          updateLevels: {
            ...updateConfig.updateLevels,
            regenerateManualAdded: selected,
          },
        };
        setUpdateConfig(updateConfigCopy);
      }
        break;
      case 'updateMainElement.regenerateAutoGenerated': {
        const updateConfigCopy = {
          ...updateConfig,
          updateMainElement: {
            ...updateConfig.updateMainElement,
            regenerateAutoGenerated: selected,
          },
        };
        setUpdateConfig(updateConfigCopy);
      }
        break;
      case 'updateMainElement.regenerateManualAdded': {
        const updateConfigCopy = {
          ...updateConfig,
          updateMainElement: {
            ...updateConfig.updateMainElement,
            regenerateManualAdded: selected,
          },
        };
        setUpdateConfig(updateConfigCopy);
      }
        break;
      case 'updateLocations.regenerateAutoGenerated': {
        const updateConfigCopy = {
          ...updateConfig,
          updateLocations: {
            ...updateConfig.updateLocations,
            regenerateAutoGenerated: selected,
          },
        };
        setUpdateConfig(updateConfigCopy);
      }
        break;
      case 'updateLocations.regenerateManualAdded': {
        const updateConfigCopy = {
          ...updateConfig,
          updateLocations: {
            ...updateConfig.updateLocations,
            regenerateManualAdded: selected,
          },
        };
        setUpdateConfig(updateConfigCopy);
      }
        break;
      default:
        break;
    }
  };

  const handleChange = (e, key) => {
    dispatch(removeSelectedArea());
    let { value } = e.target;
    value = parseFloat(value);
    switch (key) {
      case 'minX':
        dispatch(setSelectionAria({ coordinates: { ...selectionArea.coordinates, ...{ minX: value } } }));
        break;
      case 'minY':
        dispatch(setSelectionAria({ coordinates: { ...selectionArea.coordinates, ...{ minY: value } } }));
        break;
      case 'maxX':
        dispatch(setSelectionAria({ coordinates: { ...selectionArea.coordinates, ...{ maxX: value } } }));
        break;
      case 'maxY':
        dispatch(setSelectionAria({ coordinates: { ...selectionArea.coordinates, ...{ maxY: value } } }));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (selectionArea.coordinates.minX
      && selectionArea.coordinates.minY
      && selectionArea.coordinates.maxX
      && selectionArea.coordinates.maxY) {
      dispatch(showSelectedArea([
        selectionArea.coordinates.minX,
        selectionArea.coordinates.minY,
        selectionArea.coordinates.maxX,
        selectionArea.coordinates.maxY,
      ]));
    }
  }, [dispatch, selectionArea]);

  return (
    <div className={classes.root}>
      <Grid container item xs={12} justify="flex-end">
        <FormControlLabel
          labelPlacement="start"
          control={<Switch color="primary" checked={selectionArea.state} onChange={handleSelectiondArea} name="state" />}
          label="Select area"
        />
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={6}>
          <FormControlLabel
            control={<Checkbox checked={updateConfig.updateLevels.regenerateAutoGenerated} onChange={(e) => { handleChangeUpdateConfig(e, 'updateLevels.regenerateAutoGenerated'); }} />}
            label="Regenerate auto generated levels"
          />
        </Grid>
        <Grid container item xs={6}>
          <FormControlLabel
            control={<Checkbox checked={updateConfig.updateLevels.regenerateManualAdded} onChange={(e) => { handleChangeUpdateConfig(e, 'updateLevels.regenerateManualAdded'); }} />}
            label="Regenerate manually updated levels"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid container item xs={6}>
          <FormControlLabel
            control={<Checkbox checked={updateConfig.updateMainElement.regenerateAutoGenerated} onChange={(e) => { handleChangeUpdateConfig(e, 'updateMainElement.regenerateAutoGenerated'); }} />}
            label="Regenerate auto generated main"
          />
        </Grid>
        <Grid container item xs={6}>
          <FormControlLabel
            control={<Checkbox checked={updateConfig.updateMainElement.regenerateManualAdded} onChange={(e) => { handleChangeUpdateConfig(e, 'updateMainElement.regenerateManualAdded'); }} />}
            label="Regenerate manually updated main"
          />
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid container item xs={6}>
          <FormControlLabel
            control={<Checkbox checked={updateConfig.updateLocations.regenerateAutoGenerated} onChange={(e) => { handleChangeUpdateConfig(e, 'updateLocations.regenerateAutoGenerated'); }} />}
            label="Regenerate locations in auto generated locations in tiles"
          />
        </Grid>
        <Grid container item xs={6}>
          <FormControlLabel
            control={<Checkbox checked={updateConfig.updateLocations.regenerateManualAdded} onChange={(e) => { handleChangeUpdateConfig(e, 'updateLocations.regenerateManualAdded'); }} />}
            label="Regenerate locations in manually updated locations in tiles"
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={6}>
          <TextField
            className={classes.txTilesGeneration}
            variant="outlined"
            label="min X"
            value={isNumber(selectionArea.coordinates.minX) ? selectionArea.coordinates.minX : ''}
            onChange={(e) => { handleChange(e, 'minX'); }}
          />
        </Grid>
        <Grid container item xs={6}>
          <TextField
            className={classes.txTilesGeneration}
            variant="outlined"
            label="min Y"
            value={isNumber(selectionArea.coordinates.minY) ? selectionArea.coordinates.minY : ''}
            onChange={(e) => { handleChange(e, 'minY'); }}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid container item xs={6}>
          <TextField
            className={classes.txTilesGeneration}
            variant="outlined"
            label="max X"
            value={isNumber(selectionArea.coordinates.maxX) ? selectionArea.coordinates.maxX : ''}
            onChange={(e) => { handleChange(e, 'maxX'); }}
          />
        </Grid>
        <Grid container item xs={6}>
          <TextField
            className={classes.txTilesGeneration}
            variant="outlined"
            label="max Y"
            value={isNumber(selectionArea.coordinates.maxY) ? selectionArea.coordinates.maxY : ''}
            onChange={(e) => { handleChange(e, 'maxY'); }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Button
          variant="contained"
          className={classes.button}
          style={{ marginTop: 10 }}
          color="primary"
          onClick={handleGenerate}
          disabled={
          !validateSelectionArea(selectionArea.coordinates.minX,
            selectionArea.coordinates.maxX,
            selectionArea.coordinates.minY,
            selectionArea.coordinates.maxY)
          }
        >
          Generate
        </Button>
      </Grid>
      <Grid container item xs={12}>
        <List data={list} listPage={listPage} />
      </Grid>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The tile generation could take longer for this area. Please confirm
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleAgree} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

TilesGeneration.propTypes = {
  selectionArea: PropTypes.shape(),
  list: PropTypes.shape(),
  listPage: PropTypes.number,
};

TilesGeneration.defaultProps = {
  selectionArea: null,
  list: [],
  listPage: 0,
};

const mapStateToProps = (state) => ({
  selectionArea: state.generate.selectionArea,
  list: state.generate.list,
  listPage: state.generate.listPage,
});

export default connect(mapStateToProps)(TilesGeneration);
