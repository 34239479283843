import React from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Box, TextField, Tab, Tabs, Grid, Button, AppBar,
} from '@material-ui/core';

import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';

import Geocoder from 'react-mapbox-gl-geocoder';
import { MAPBOX_ACCESS_TOKEN } from '../../../constants';
import {
  setViewport, downloadTilesLatLon, clickOnMap,
} from '../../../store/mapbox/actions';

import {
  tile2long, tile2lat, validateLong, validateLat, validateX, validateY,
} from '../../../helpers/gHelper';

import './search.css';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      style={{ flex: 272 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  textField: {
    width: '100%',
    padding: '0 2px',
  },
  tabWidth: {
    minWidth: '178px',
    fontWeight: '900',
  },
  button: {
    width: '100%',
    margin: '8px 0',
  },
  panel: {
    padding: 0,
    '& .MuiBox-root': {
      padding: '24px !important',
    },
  },

  tabHeader: {
    backgroundColor: 'rgb(38, 38, 38, 0.1)',
  },

}));

const Search = ({
  viewport, pathToS3,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    x, y, latitudeTmp, longitudeTmp,
  } = viewport;

  const [tabOrder, setTabOrder] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabOrder(newValue);
  };

  const inputComponent = (props) => (
    <TextField
      {...props}
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      label="Address"
      className={classes.textField}
    />
  );

  const onSelected = (selectedViewport) => {
    dispatch(setViewport({
      latitude: selectedViewport.latitude,
      longitude: selectedViewport.longitude,
      zoom: 15.1,
    }));
    dispatch(clickOnMap([parseFloat(selectedViewport.longitude), parseFloat(selectedViewport.latitude)]));
    dispatch(downloadTilesLatLon(selectedViewport.latitude, selectedViewport.longitude, 15.4));
  };

  const handleChangeLongitude = (event) => {
    dispatch(setViewport({
      longitudeTmp: event.target.value.trim(),
    }));
  };

  const handleChangeLatitude = (event) => {
    dispatch(setViewport({
      latitudeTmp: event.target.value.trim(),
    }));
  };

  const handleChangeX = (event) => {
    dispatch(setViewport({
      x: event.target.value.trim(),
    }));
  };

  const handleChangeY = (event) => {
    dispatch(setViewport({
      y: event.target.value.trim(),
    }));
  };

  const handleSaveCoordinates = () => {
    dispatch(setViewport({
      longitude: tile2long(x, 17),
      latitude: tile2lat(y, 17),
      zoom: 15.1,
    }));

    dispatch(clickOnMap([tile2long(parseFloat(x), 17), tile2lat(parseFloat(y), 17)]));
    dispatch(downloadTilesLatLon(tile2lat(parseFloat(y), 17), tile2long(parseFloat(x), 17), 15.4));
  };

  const handleSaveLatLon = () => {
    dispatch(setViewport({
      longitude: longitudeTmp,
      latitude: latitudeTmp,
      zoom: 15.4,
    }));
    dispatch(clickOnMap([parseFloat(longitudeTmp), parseFloat(latitudeTmp)]));
    dispatch(downloadTilesLatLon(latitudeTmp, longitudeTmp, 15.4));
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.tabHeader} position="static">
        <Tabs
          className={classes.tabPanel}
          value={tabOrder}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="off"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab className={classes.tabWidth} icon={<LanguageOutlinedIcon />} {...a11yProps(0)} />
          <Tab className={classes.tabWidth} label="XY" {...a11yProps(1)} />
          <Tab className={classes.tabWidth} icon={<HomeOutlinedIcon />} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabOrder} className={classes.panel} index={0}>
        <div className={classes.root}>
          <Grid container item xs={12}>
            <Grid container item xs={6}>

              <TextField
                className={classes.textField}
                id="latitude"
                label="Latitude"
                value={latitudeTmp}
                onChange={handleChangeLatitude}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />

            </Grid>
            <Grid container item xs={6}>
              <TextField
                className={classes.textField}
                id="longitude"
                label="Longitude"
                value={longitudeTmp}
                onChange={handleChangeLongitude}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />

            </Grid>
            <Button variant="contained" className={classes.button} color="primary" onClick={handleSaveLatLon} disabled={!validateLong(longitudeTmp) || !validateLat(latitudeTmp)}>
              Find
            </Button>
          </Grid>
        </div>
      </TabPanel>
      <TabPanel className={classes.panel} value={tabOrder} index={1}>
        <div className={classes.root}>
          <Grid container item xs={12}>
            <Grid container item xs={6}>

              <TextField
                className={classes.textField}
                id="coords-x"
                label="X"
                type="number"
                value={x}
                onChange={handleChangeX}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />

            </Grid>
            <Grid container item xs={6}>
              <TextField
                className={classes.textField}
                id="coords-y"
                label="Y"
                type="number"
                value={y}
                onChange={handleChangeY}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />

            </Grid>
            <Button variant="contained" className={classes.button} color="primary" onClick={handleSaveCoordinates} disabled={!validateX(x) || !validateY(y)}>
              Find
            </Button>
          </Grid>
        </div>
      </TabPanel>
      <TabPanel value={tabOrder} className={classes.panel} index={2}>
        <div className={classes.root}>
          <Grid container item xs={12}>
            <Geocoder
              mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
              onSelected={onSelected}
              viewport={viewport}
              hideOnSelect
              updateInputOnSelect
              value="5"
              inputComponent={inputComponent}
              limit={10}
            />
          </Grid>
        </div>

      </TabPanel>
      {pathToS3 && (
      <Grid container item xs={12} style={{ paddingLeft: 28 }}>
        <Grid container item xs={3}>
          Path to s3:
        </Grid>
        <Grid container item xs={9}>
          {pathToS3}
        </Grid>
      </Grid>
      )}
      <div />
    </div>
  );
};

Search.propTypes = {
  viewport: PropTypes.shape(),
  pathToS3: PropTypes.string,
};

Search.defaultProps = {
  viewport: null,
  pathToS3: undefined,
};

const mapStateToProps = (state) => ({
  viewport: state.mapbox.viewport,
  pathToS3: state.mapbox.pathToS3,
});

export default connect(mapStateToProps)(Search);
