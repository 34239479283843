import AlertTypes from './types';

const initialState = {
  list: [],
};

export default function alertReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case AlertTypes.SET_ALERT:
      return {
        ...state,
        list: [...state.list,
          action.payload,
        ],
      };
    case AlertTypes.DELETE_ALERT:
      return {
        ...state,
        list: state.list.filter((alert) => alert.key !== action.payload),
      };
    case AlertTypes.CLEAR_ALERT:
      return {
        ...state,
        list: [],
      };
    default:
      return state;
  }
}
