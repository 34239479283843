const types = {
  UPDATE_SELECTION_AREA: 'UPDATE_SELECTION_AREA',
  ADD_POINT_SELECTION_AREA: 'ADD_POINT_SELECTION_AREA',
  RESET_SELECTION_AREA: 'RESET_SELECTION_AREA',
  FETCH_LIST_SUCCESS: 'FETCH_LIST_SUCCESS',
  FETCH_DELETE_REQUEST: 'FETCH_DELETE_REQUEST',
  SET_LIST_PAGE: 'SET_LIST_PAGE',
  ADD_TO_LIST: 'ADD_TO_LIST',
  FETCH_AREAS_SUCCESS: 'FETCH_AREAS_SUCCESS',
  ADD_ZOOM_LOADED_GENERATED_AREAS: 'ADD_ZOOM_LOADED_GENERATED_AREAS',
};
export default types;
