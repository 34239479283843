import * as turf from '@turf/turf';
import { v4 as uuidv4 } from 'uuid';
import types from './types';
import {
  lon2tile, lat2tile, tile2lat, tile2long, getTimeStamp,
} from '../../helpers/gHelper';
import * as gHelper from '../../helpers/gHelper';

const arrayElementTypes = [
  { key: 'water', layersPanelIndex: 6 },
  { key: 'air', layersPanelIndex: 7 },
  { key: 'forest', layersPanelIndex: 8 },
  { key: 'ground', layersPanelIndex: 9 },
];

const toggleableLayerDefaultState = [
  { // 0
    id: 'tiles-helsinki-layer',
    name: 'Tiles',
    show: true,
    source: {
      type: 'FeatureCollection',
      features: [
      ],
    },
    type: 'fill',
    paint: {
      'fill-color': {
        type: 'identity',
        property: 'color',
      },
      'fill-outline-color': 'rgba(200, 100, 240, 0.2)',
    },
  },
  { // 1
    id: 'points-helsinki-layer',
    name: 'Points',
    show: true,
    source: {
      type: 'FeatureCollection',
      features: [
      ],
    },
    type: 'circle',
    paint: {
      'circle-radius': 6,
      'circle-color': {
        type: 'identity',
        property: 'color',
      },
    },
  },
  { // 2
    id: 'content-helsinki-layer',
    name: 'Content-Border',
    show: true,
    source: {
      type: 'FeatureCollection',
      features: [
      ],
    },
    type: 'line',
    'line-join': 'bevel',
    'line-cap': 'round',
    paint: {
      'line-color': {
        type: 'identity',
        property: 'color',
      },
      'line-width': 3,
    },
  },
  { // 3
    id: 'content-fill-helsinki-layer',
    name: 'Content',
    show: true,
    source: {
      type: 'FeatureCollection',
      features: [
      ],
    },
    type: 'fill',
    paint: {
      'fill-color': 'transparent',
    },
  },
  { // 4
    id: 'selected-tile-layer',
    name: 'Selected Tile',
    show: true,
    cluster: true,
    source: {
      type: 'FeatureCollection',
      features: [
      ],
    },
    type: 'fill',
    paint: {
      'fill-color': 'transparent',
      'fill-outline-color': 'rgba(1, 1, 1, 1)',
    },
  },
  { // 5
    id: 'generation-tiles-areas-layer',
    name: 'Generation Tile areas',
    show: true,
    source: {
      type: 'FeatureCollection',
      features: [
      ],
    },
    type: 'fill',
    paint: {
      'fill-color': '#000',
      'fill-opacity': 0.3,
    },
  },
  { // 6
    id: 'tile-levels',
    type: 'symbol',
    source: {
      type: 'FeatureCollection',
      features: [
      ],
    },
    name: 'Levels of tiles',
    show: true,
    layout: {
      'text-field': ['get', 'description'],
      'text-variable-anchor': ['center'],
      // 'text-radial-offset': 1,
      'text-justify': 'center',
      'text-size': 12,
    },
    paint: {
      'text-opacity': 0.5,
      'text-color': {
        type: 'identity',
        property: 'color',
      },
      'text-halo-color': {
        type: 'identity',
        property: 'halocolor',
      },
      'text-halo-width': 1,
    },
  },
];

const zoomDefault = 17;
const listLayersPanelDefault = [
  { // 0
    id: 'tiles',
    show: true,
    inPanel: true,
    name: 'Tiles',
    sourceLayersTemplates: [toggleableLayerDefaultState[0]],
    layers: [],
  },
  { // 1
    id: 'points',
    show: true,
    inPanel: true,
    name: 'Points',
    sourceLayersTemplates: [toggleableLayerDefaultState[1]],
    layers: [],
  },
  { // 2
    id: 'content',
    show: true,
    inPanel: true,
    name: 'Content',
    sourceLayersTemplates: [toggleableLayerDefaultState[2], toggleableLayerDefaultState[3]],
    layers: [],
  },
  { // 3
    id: 'selected-tile',
    show: true,
    inPanel: false,
    name: 'Selected Tile',
    sourceLayersTemplates: [toggleableLayerDefaultState[4]],
    layers: [],
  },
  { // 4
    id: 'zoom-grid',
    show: true,
    inPanel: true,
    name: 'Grid',
    sourceLayersTemplates: [toggleableLayerDefaultState[4]],
    layers: [],
  },
  { // 5
    id: 'selected-area',
    show: true,
    inPanel: false,
    name: 'Selected Area',
    sourceLayersTemplates: [toggleableLayerDefaultState[4]],
    layers: [],
  },
  { // 6
    id: 'tile-poligons-water',
    show: true,
    inPanel: true,
    name: 'Water TD',
    sourceLayersTemplates: [{ ...toggleableLayerDefaultState[0], id: 'water-td' }],
    layers: [],
  },
  { // 7
    id: 'tile-poligons-air',
    show: true,
    inPanel: true,
    name: 'Air TD',
    sourceLayersTemplates: [{ ...toggleableLayerDefaultState[0], id: 'air-td' }],
    layers: [],
  },
  { // 8
    id: 'tile-poligons-forest',
    show: true,
    inPanel: true,
    name: 'Forest TD',
    sourceLayersTemplates: [{ ...toggleableLayerDefaultState[0], id: 'forest-td' }],
    layers: [],
  },
  { // 9
    id: 'tile-poligons-ground',
    show: true,
    inPanel: true,
    name: 'Ground TD',
    sourceLayersTemplates: [{ ...toggleableLayerDefaultState[0], id: 'ground-td' }],
    layers: [],
  },
  { // 10
    id: 'generation-in-progress ',
    show: true,
    inPanel: true,
    name: 'Generation in progress ',
    sourceLayersTemplates: [toggleableLayerDefaultState[5]],
    layers: [],
  },
  { // 11
    id: 'generated-areas ',
    show: true,
    inPanel: true,
    name: 'Generated areas ',
    sourceLayersTemplates: [toggleableLayerDefaultState[4]],
    layers: [],
  },
  { // 12
    id: 'tile-level',
    show: true,
    inPanel: true,
    name: 'Levels of tiles ',
    sourceLayersTemplates: [toggleableLayerDefaultState[6]],
    layers: [],
  },
  { // 13
    id: 'selected-location',
    show: true,
    inPanel: false,
    name: 'selected location',
    sourceLayersTemplates: [toggleableLayerDefaultState[4]],
    layers: [],
  },
  { // 14
    id: 'cicle-druggable',
    show: true,
    inPanel: false,
    name: 'cicle druggable with 10m radius',
    sourceLayersTemplates: [toggleableLayerDefaultState[2]],
    layers: [],
  },
];

const initialState = {
  pathToS3: undefined,
  tileDetails: undefined,
  contentResult: undefined,
  randomLocationsResult: undefined,
  contentStatus: 200,
  contentLocationSelected: undefined,
  locationSelected: undefined,
  selectedPlaceOnMap: undefined,
  draggableCircleLocation: undefined,
  markerLocation: {
    latitude: 37.78,
    longitude: -122.41,
    show: false,
    centerCicleValid: true,
  },
  viewport: {
    latitude: 60.1672484,
    longitude: 24.9703186,
    zoom: 2,
    x: '',
    y: '',
    latitudeTmp: '',
    longitudeTmp: '',
  },
  listLayersPanel: listLayersPanelDefault,
  mapDataLoading: {
    arrayOfTiles: [],
    isLoading: false,
  },
  tilesInfo: {
    coordinates: {
      latitude: '',
      longitude: '',
    },
    result: {},
    status: null,
  },
};

function getColorHaloColor(level) {
  return {
    color: level === 4 ? 'rgba(255,255,255, 1)' : 'rgba(0, 0, 0, 1)',
    halocolor: level === 4 ? 'rgba(255, 0, 0, 1)' : 'rgba(255,255,255, 1)',
  };
}

function addPointsToLayer(action, listLayersPanel) {
  const layersPanel = [...listLayersPanel];
  const template = {
    ...layersPanel[1].sourceLayersTemplates[0],
    source: {
      ...layersPanel[1].sourceLayersTemplates[0].source, features: [...action.payload.locations],
    },
  };
  template.id = `${template.id}-${uuidv4()}`;
  template.gcs14 = action.payload.gcs14;
  template.show = layersPanel[1].show;
  layersPanel[1].layers = [...layersPanel[1].layers, template];
  // });
  return layersPanel;
}

export default function settingsReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case types.UPDATE_LAYERSSTATE:
      return {
        ...state,
        listLayersPanel: action.payload,
      };
    case types.CLICK_ON_MAP: {
      const layersPanel = [...state.listLayersPanel];
      const gcs = {
        x: lon2tile(action.payload[0], zoomDefault),
        y: lat2tile(action.payload[1], zoomDefault),
      };
      const path = gHelper.getKeyForS3(gcs.x, gcs.y);

      const template = {
        ...layersPanel[3].sourceLayersTemplates[0],
        source: {
          ...layersPanel[3].sourceLayersTemplates[0].source, features: [turf.bboxPolygon(gHelper.getTileCoordinatesMinMax(gcs.x, gcs.y, zoomDefault))],
        },
        id: `${layersPanel[3].sourceLayersTemplates[0].id}-${uuidv4()}`,
      };
      layersPanel[3].layers = [template];
      return {
        ...state,
        pathToS3: path,
        selectedPlaceOnMap: { lng: action.payload[0], lat: action.payload[1], gcs },
        listLayersPanel: layersPanel,
      };
    }
    case types.SHOW_SELECTED_AREA: {
      const layersPanel = [...state.listLayersPanel];
      const template = {
        ...layersPanel[5].sourceLayersTemplates[0],
        source: {
          ...layersPanel[5].sourceLayersTemplates[0].source,
          features: [turf.bboxPolygon([
            tile2long(action.payload[0], zoomDefault),
            tile2lat(action.payload[1], zoomDefault),
            tile2long(action.payload[2] + 1, zoomDefault),
            tile2lat(action.payload[3] + 1, zoomDefault),
          ])],
        },
        id: `${layersPanel[5].sourceLayersTemplates[0].id}-${uuidv4()}`,
      };
      layersPanel[5].layers = [template];
      return {
        ...state,
        // selectedPlaceOnMap: { lng: action.payload[0], lat: action.payload[1], gcs },
        listLayersPanel: layersPanel,
      };
    }
    case types.REMOVE_SELECTED_AREA: {
      const layersPanel = [...state.listLayersPanel];

      layersPanel[5].layers = [];
      return {
        ...state,
        listLayersPanel: layersPanel,
      };
    }
    case types.UPDATE_TILE_DETAILS: {
      const layersPanel = [...state.listLayersPanel];
      for (let i = 0; i < state.listLayersPanel[0].layers.length; i += 1) {
        const tileIndex = state.listLayersPanel[0].layers[i].source.features.findIndex((el) => el.properties.gcs.x === action.payload.gcs.x
        && el.properties.gcs.y === action.payload.gcs.y);

        if (tileIndex !== -1) {
          const copyTile = { ...state.listLayersPanel[0].layers[i].source.features[tileIndex] };
          copyTile.properties.main = action.payload.main;
          copyTile.properties.level = action.payload.level;
          copyTile.properties.color = action.payload.color;

          const newLayer = { ...layersPanel[0].layers[i] };
          newLayer.source.features.splice(tileIndex, 1, copyTile);
          newLayer.id = `${layersPanel[0].sourceLayersTemplates[0].id}-${uuidv4()}`;

          layersPanel[0].layers.splice(i, 1, newLayer);
        }
      }
      // update level
      for (let i = 0; i < state.listLayersPanel[12].layers.length; i += 1) {
        const levelIndex = state.listLayersPanel[12].layers[i].source.features.findIndex((el) => el.properties.gcs.x === action.payload.gcs.x
        && el.properties.gcs.y === action.payload.gcs.y);

        if (levelIndex !== -1) {
          const copyTile = { ...state.listLayersPanel[12].layers[i].source.features[levelIndex] };
          copyTile.properties.description = action.payload.level;
          const { color, halocolor } = getColorHaloColor(action.payload.level);
          copyTile.properties.color = color;
          copyTile.properties.halocolor = halocolor;

          const newLayer = { ...layersPanel[12].layers[i] };
          newLayer.source.features.splice(levelIndex, 1, copyTile);
          newLayer.id = `${layersPanel[12].sourceLayersTemplates[0].id}-${uuidv4()}`;

          layersPanel[12].layers.splice(i, 1, newLayer);
        }
      }

      return {
        ...state,
        listLayersPanel: layersPanel,
      };
    }
    case types.SET_TILE_POLIGONS_DETAILS: {
      const layersPanel = [...state.listLayersPanel];
      arrayElementTypes.forEach((item) => {
        const featuresByKey = [...action.payload].filter((el) => el.properties.key === item.key);

        const template = {
          ...layersPanel[item.layersPanelIndex].sourceLayersTemplates[0],
          source: {
            ...layersPanel[item.layersPanelIndex].sourceLayersTemplates[0].source, features: featuresByKey,
          },
        };
        template.id = `${template.id}-${uuidv4()}`;
        template.show = layersPanel[item.layersPanelIndex].show;
        layersPanel[item.layersPanelIndex].layers = [template];
      });
      return {
        ...state,
        listLayersPanel: layersPanel,
      };
    }
    case types.ADD_TILES: {
      const layersPanel = [...state.listLayersPanel];
      const levelsLayer = action.payload.features.map((feature) => {
        const { color, halocolor } = getColorHaloColor(feature.properties.level);
        const levelFeature = {
          type: 'Feature',
          properties: {
            description: feature.properties.level,
            color,
            halocolor,
            gcs: feature.properties.gcs,
          },
          geometry: {
            type: 'Point',
            coordinates: [
              tile2long(feature.properties.gcs.x + 0.9, zoomDefault),
              tile2lat(feature.properties.gcs.y + 0.1, zoomDefault),
            ],
          },
        };

        return levelFeature;
      });

      const levelsTemplate = {
        ...layersPanel[12].sourceLayersTemplates[0],
        source: {
          ...layersPanel[12].sourceLayersTemplates[0].source, features: levelsLayer,
        },
        id: `${layersPanel[12].sourceLayersTemplates[0].id}-${uuidv4()}`,
        show: layersPanel[12].show,
      };
      layersPanel[12].layers = [...layersPanel[12].layers, levelsTemplate];

      const template = {
        ...layersPanel[0].sourceLayersTemplates[0],
        source: {
          ...layersPanel[0].sourceLayersTemplates[0].source, features: [...action.payload.features],
        },
      };
      template.id = `${template.id}-${uuidv4()}`;
      template.show = layersPanel[0].show;
      layersPanel[0].layers = [...layersPanel[0].layers, template];

      const gcs14 = gHelper.convertGCSZoom(action.payload.features[0].properties.gcs.x, action.payload.features[0].properties.gcs.y, 17, 14);
      // add grid in 14 zoom
      const templateGrid = {
        ...layersPanel[4].sourceLayersTemplates[0],
        source: {
          ...layersPanel[4].sourceLayersTemplates[0].source, features: [turf.bboxPolygon(gHelper.getTileCoordinatesMinMax(gcs14.x, gcs14.y, 14))],
        },
        id: `${layersPanel[4].sourceLayersTemplates[0].id}-${uuidv4()}`,
      };
      templateGrid.show = layersPanel[4].show;
      templateGrid.before = template.id;
      layersPanel[4].layers = [...layersPanel[4].layers, templateGrid];

      return {
        ...state,
        listLayersPanel: layersPanel,
      };
    }
    case types.ADD_POINTS: {
      const layersPanel = addPointsToLayer(action, state.listLayersPanel);
      return {
        ...state,
        listLayersPanel: layersPanel,
      };
    }
    case types.CLEAR_EXPIRED_CONTENT: {
      const layersPanel = [...state.listLayersPanel];
      const currentTimeStamp = getTimeStamp(0);
      const template1 = {
        ...layersPanel[2].sourceLayersTemplates[0],
        source: {
          ...layersPanel[2].sourceLayersTemplates[0].source,
          features: [...(layersPanel[2].layers.length > 0 ? layersPanel[2].layers[0].source.features.filter((el) => el.properties.data.timestamp > currentTimeStamp) : [])],
        },
        id: `${layersPanel[2].sourceLayersTemplates[0].id}-${uuidv4()}`,
      };
      const template2 = {
        ...layersPanel[2].sourceLayersTemplates[1],
        source: {
          ...layersPanel[2].sourceLayersTemplates[1].source,
          features: [...(layersPanel[2].layers.length > 0 ? layersPanel[2].layers[1].source.features.filter((el) => el.properties.data.timestamp > currentTimeStamp) : [])],
        },
        id: `${layersPanel[2].sourceLayersTemplates[1].id}-${uuidv4()}`,
      };

      layersPanel[2].layers = [template1, template2];

      return {
        ...state,
        listLayersPanel: layersPanel,
      };
    }
    case types.UPDATE_CONTENT_LAYER: {
      const layersPanel = [...state.listLayersPanel];
      const template1 = {
        ...layersPanel[2].sourceLayersTemplates[0],
        source: {
          ...layersPanel[2].sourceLayersTemplates[0].source, features: [...(layersPanel[2].layers.length > 0 ? layersPanel[2].layers[0].source.features : []), ...action.payload],
        },
        id: `${layersPanel[2].sourceLayersTemplates[0].id}-${uuidv4()}`,
      };

      const template2 = {
        ...layersPanel[2].sourceLayersTemplates[1],
        source: {
          ...layersPanel[2].sourceLayersTemplates[1].source, features: [...(layersPanel[2].layers.length > 0 ? layersPanel[2].layers[1].source.features : []), ...action.payload],
        },
        id: `${layersPanel[2].sourceLayersTemplates[1].id}-${uuidv4()}`,
      };

      layersPanel[2].layers = [template1, template2];
      return {
        ...state,
        listLayersPanel: layersPanel,
      };
    }
    case types.SET_TILE_DETAILS: {
      return {
        ...state,
        tileDetails: action.payload,
      };
    }
    case types.SET_CONTENT_RESULT: {
      const data = action.payload;
      const { status } = data;

      delete data.status;
      return {
        ...state,
        contentResult: action.payload,
        contentStatus: status,
      };
    }
    case types.SET_RANDOMLOCATIONS_RESULT: {
      return {
        ...state,
        randomLocationsResult: action.payload,
      };
    }
    case types.CLEAR_TILE_DETAILS: {
      const layersPanel = [...state.listLayersPanel];

      arrayElementTypes.forEach((item) => {
        layersPanel[item.layersPanelIndex].layers = [];
      });
      return {
        ...state,
        tileDetails: undefined,
        listLayersPanel: layersPanel,

      };
    }
    case types.SET_SELECTED_CONTENT_LOCATION: {
      return {
        ...state,
        contentLocationSelected: action.payload,
      };
    }
    case types.DRAW_CIRCLE_FOR_MARKERLOCATION: {
      const layersPanel = updateCicleInLayers(action.payload, state);

      return {
        ...state,
        listLayersPanel: layersPanel,
        draggableCircleLocation: action.payload,
      };
    }
    case types.SET_SELECTED_LOCATION: {
      const layersPanel = [...state.listLayersPanel];
      if (action.payload && state.locationSelected && action.payload.coordinatesOriginal[0] === state.locationSelected.coordinatesOriginal[0]
        && action.payload.coordinatesOriginal[1] === state.locationSelected.coordinatesOriginal[1]) {
        layersPanel[13].show = false;
        layersPanel[13].layers = layersPanel[13].layers.map((layer) => ({ ...layer, show: false }));
        return {
          ...state,
          listLayersPanel: layersPanel,
          locationSelected: undefined,
        };
      }

      let circle;
      if (action.payload) {
        const options = { steps: 100, units: 'meters', properties: { foo: 'bar' } };
        circle = turf.circle(action.payload.coordinatesOriginal, 10, options);
      }

      const template = {
        ...layersPanel[13].sourceLayersTemplates[0],
        source: {
          ...layersPanel[13].sourceLayersTemplates[0].source, features: circle ? [circle] : [],
        },
        id: `${layersPanel[13].sourceLayersTemplates[0].id}-${uuidv4()}`,
      };
      layersPanel[13].layers = [template];

      return {
        ...state,
        listLayersPanel: layersPanel,
        locationSelected: action.payload,
      };
    }
    case types.UPDATE_LOCATIONS_BY_BIG_TILE: {
      let layersPanel = [...state.listLayersPanel];
      layersPanel[1].layers = layersPanel[1].layers.filter((el) => !(el.gcs14.x === action.payload.gcs14.x && el.gcs14.y === action.payload.gcs14.y));
      layersPanel = addPointsToLayer(action, layersPanel);
      return {
        ...state,
        listLayersPanel: layersPanel,
      };
    }
    case types.LOADING_MAP_REMOVE: {
      const arrayOfTiles = state.mapDataLoading.arrayOfTiles.filter((el) => el !== action.payload);
      return {
        ...state,
        mapDataLoading: {
          arrayOfTiles,
          isLoading: arrayOfTiles.length > 0,
        },
      };
    }
    case types.LOADIND_MAP_ADD: {
      const arrayOfTiles = [...state.mapDataLoading.arrayOfTiles, action.payload];
      return {
        ...state,
        mapDataLoading: {
          arrayOfTiles,
          isLoading: arrayOfTiles.length > 0,
        },
      };
    }
    case types.SET_VIEWPORT: {
      return {
        ...state,
        viewport: { ...state.viewport, ...action.payload },
      };
    }
    case types.SHOW_HIDE_MARKER_LOCATION: {
      const markerLocation = { ...state.markerLocation, ...action.payload };
      if (action.payload.show) {
        if (state.selectedPlaceOnMap) {
          markerLocation.longitude = state.selectedPlaceOnMap.lng;
          markerLocation.latitude = state.selectedPlaceOnMap.lat;
        } else {
          markerLocation.longitude = state.viewport.longitude;
          markerLocation.latitude = state.viewport.latitude;
        }
      }
      const layersPanel = updateCicleInLayers({ longitude: markerLocation.longitude, latitude: markerLocation.latitude }, state, action.payload.show);

      return {
        ...state,
        markerLocation,
        layersPanel,
      };
    }
    case types.SET_MARKER_LOCATION: {
      const layersPanel = updateCicleInLayers(action.payload, state);

      const markerLocation = { ...state.markerLocation, ...action.payload };
      return {
        ...state,
        markerLocation,
        layersPanel,
      };
    }
    case types.SET_VIEWPORT_LATITUDE: {
      return {
        ...state,
        viewport: { ...state.viewport, latitude: action.payload },
      };
    }
    case types.SET_VIEWPORT_LONGITUDE: {
      return {
        ...state,
        viewport: { ...state.viewport, longitude: action.payload },
      };
    }
    case types.SET_VIEWPORT_X: {
      return {
        ...state,
        viewport: { ...state.viewport, x: action.payload },
      };
    }
    case types.SET_VIEWPORT_Y: {
      return {
        ...state,
        viewport: { ...state.viewport, y: action.payload },
      };
    }
    case types.SET_VIEWPORT_ZOOM: {
      return {
        ...state,
        viewport: { ...state.viewport, zoom: action.payload },
      };
    }
    case types.SET_TILES_INFO: {
      return {
        ...state,
        tilesInfo: { ...state.tilesInfo, ...action.payload },
      };
    }
    case types.SHOW_GENERATION_TILES_AREAS: {
      const layersPanel = [...state.listLayersPanel];
      const features = [];
      action.payload.forEach((request) => {
        if (['pending', 'in progress'].includes(request.state)) {
          const feature = turf.bboxPolygon([
            tile2long(request.area.minx, zoomDefault),
            tile2lat(request.area.minY, zoomDefault),
            tile2long(request.area.maxX + 1, zoomDefault),
            tile2lat(request.area.maxY + 1, zoomDefault),
          ]);

          feature.properties.id = request.id;
          features.push(feature);
        }
      });

      const template = {
        ...layersPanel[10].sourceLayersTemplates[0],
        source: {
          ...layersPanel[10].sourceLayersTemplates[0].source, features,
        },
        id: `${layersPanel[10].sourceLayersTemplates[0].id}-${uuidv4()}`,
      };
      layersPanel[10].layers = [template];
      return {
        ...state,

        listLayersPanel: layersPanel,
      };
    }

    case types.SHOW_GENERATED_AREAS: {
      const layersPanel = [...state.listLayersPanel];
      const features = [];
      const options = { precision: 6 };
      if (state.listLayersPanel && state.listLayersPanel[11] && state.listLayersPanel[11].source) console.log('source', state.listLayersPanel[11].source.length);
      action.payload.forEach((coordinates) => {
        const feature = turf.bboxPolygon(
          gHelper.getTileCoordinatesMinMax(coordinates.x, coordinates.y, coordinates.zoom),
        );
        feature.geometry = turf.truncate(feature.geometry, options);
        features.push(feature);
      });
      const template = {
        ...layersPanel[11].sourceLayersTemplates[0],
        source: {
          ...layersPanel[11].sourceLayersTemplates[0].source,
          features,
        },
        id: `${layersPanel[11].sourceLayersTemplates[0].id}-${uuidv4()}`,
      };
      layersPanel[11].layers = [...layersPanel[11].layers, template];
      return {
        ...state,

        listLayersPanel: layersPanel,
      };
    }

    default:
      return state;
  }
}
function updateCicleInLayers(lonLat, state, show = true) {
  const layersPanel = [...state.listLayersPanel];
  if (show !== undefined && !show) {
    layersPanel[14].show = show;
    layersPanel[14].layers = layersPanel[14].layers.map((layer) => ({ ...layer, show }));
    return layersPanel;
  }

  let circle;
  if (lonLat) {
    const options = { steps: 100, units: 'meters', properties: { foo: 'bar' } };
    circle = turf.circle([lonLat.longitude, lonLat.latitude], 10, options);
    circle.properties = { color: 'rgba(149, 165, 166, 0.8)' };
  }

  const template = {
    ...layersPanel[14].sourceLayersTemplates[0],
    source: {
      ...layersPanel[14].sourceLayersTemplates[0].source, features: circle ? [circle] : [],
    },
    id: `${layersPanel[14].sourceLayersTemplates[0].id}-${uuidv4()}`,
    show,
  };
  layersPanel[14].layers = [template];
  return layersPanel;
}
