const types = {
  UPDATE_LAYERSSTATE: 'UPDATE_LAYERSSTATE',
  ADD_TILES: 'ADD_TILES',
  ADD_POINTS: 'ADD_POINTS',
  UPDATE_CONTENT_LAYER: 'UPDATE_CONTENT_LAYER',
  SET_TILE_DETAILS: 'SET_TILE_DETAILS',
  CLEAR_TILE_DETAILS: 'CLEAR_TILE_DETAILS',
  SET_CONTENT_RESULT: 'SET_CONTENT_RESULT',
  SET_SELECTED_CONTENT_LOCATION: 'SET_SELECTED_CONTENT_LOCATION',
  UPDATE_TILE_DETAILS: 'UPDATE_TILE_DETAILS',
  CLICK_ON_MAP: 'CLICK_ON_MAP',
  SET_VIEWPORT_LATITUDE: 'SET_VIEWPORT_LATITUDE',
  SET_VIEWPORT_LONGITUDE: 'SET_VIEWPORT_LONGITUDE',
  SET_VIEWPORT_X: 'SET_VIEWPORT_X',
  SET_VIEWPORT_Y: 'SET_VIEWPORT_Y',
  SET_VIEWPORT_ZOOM: 'SET_VIEWPORT_ZOOM',
  SET_VIEWPORT: 'SET_VIEWPORT',
  LOADING_MAP_REMOVE: 'LOADING_MAP_REMOVE',
  LOADIND_MAP_ADD: 'LOADIND_MAP_ADD',
  SHOW_SELECTED_AREA: 'SHOW_SELECTED_AREA',
  REMOVE_SELECTED_AREA: 'REMOVE_SELECTED_AREA',
  SET_TILE_POLIGONS_DETAILS: 'SET_TILE_POLIGONS_DETAILS',
  SET_TILES_INFO: 'SET_TILES_INFO',
  SHOW_GENERATION_TILES_AREAS: 'SHOW_GENERATION_TILES_AREAS',
  SHOW_GENERATED_AREAS: 'SHOW_GENERATED_AREAS',
  SET_SELECTED_LOCATION: 'SET_SELECTED_LOCATION',
  UPDATE_LOCATIONS_BY_BIG_TILE: 'UPDATE_LOCATIONS_BY_BIG_TILE',
  SET_MARKER_LOCATION: 'SET_MARKER_LOCATION',
  SHOW_HIDE_MARKER_LOCATION: 'SHOW_HIDE_MARKER_LOCATION',
  DRAW_CIRCLE_FOR_MARKERLOCATION: 'DRAW_CIRCLE_FOR_MARKERLOCATION',
  CLEAR_EXPIRED_CONTENT: 'CLEAR_EXPIRED_CONTENT',
  SET_RANDOMLOCATIONS_RESULT: 'SET_RANDOMLOCATIONS_RESULT',
};
export default types;
