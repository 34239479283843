const checkToken = () => {
  if (localStorage.getItem('accessToken')) {
    return true;
  }

  return false;
};

const getToken = (name) => {
  const token = localStorage.getItem(name);
  return token;
};

const setToken = (tokenName = 'accessToken', token) => {
  localStorage.setItem(tokenName, token);
};

const removeToken = (tokenName = 'accessToken') => {
  localStorage.removeItem(tokenName);
};

const getAuthHeader = () => {
  const token = getToken('accessToken');
  return `Bearer ${token}`;
};

export {
  checkToken, setToken, removeToken, getAuthHeader, getToken,
};
