import { isNumber } from '@turf/turf';

const RADIUS_OF_EARTH = 6371000; // radius of earth in m

const getPerimeter = (count, center) => {
  const delta = (count - 1) / 2;
  const xMin = center.x - delta;
  const yMin = center.y - delta;
  const xMax = center.x + delta;
  const yMax = center.y + delta;

  let coords = [...Array(count).keys()].map((i) => ({ x: i + xMin, y: yMin }));
  coords = [...coords, ...[...Array(count).keys()].map((i) => ({ x: i + xMin, y: yMax }))];
  coords = [...coords, ...[...Array(count - 2).keys()].map((i) => ({ x: xMin, y: yMin + 1 + i }))];
  coords = [...coords, ...[...Array(count - 2).keys()].map((i) => ({ x: xMax, y: yMin + 1 + i }))];
  return coords;
};

const get8TilesAround = (center, perimeterSizes) => {
  let coords = [];
  perimeterSizes.forEach((length) => {
    coords = [...coords, ...getPerimeter(length, center)];
  });
  return coords;
};

const lon2tile = (lon, zoom) => {
   return Math.floor((lon + 180) / 360 * (2 ** zoom))};

const lat2tile = (lat, zoom) => (Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * (2 ** zoom)));

const tile2long = (x, z) => (x / (2 ** z) * 360 - 180);

const tile2lat = (y, z) => {
  const n = Math.PI - 2 * Math.PI * y / (2 ** z);
  return (180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))));
};

const ToRadians = (val) => (Math.PI / 180) * val;

const ToDegrees = (radians) => {
  const degrees = (180 / Math.PI) * radians;
  return (degrees);
};

const destinationPoint = (cor, distance, bearing) => {
  bearing = ToRadians(bearing);
  const lat = ToRadians(cor.lat);
  const lon = ToRadians(cor.lng);
  const latR = Math.asin(Math.sin(lat) * Math.cos(distance / RADIUS_OF_EARTH) + Math.cos(lat) * Math.sin(distance / RADIUS_OF_EARTH) * Math.cos(bearing));
  const lngR = lon + Math.atan2(Math.sin(bearing) * Math.sin(distance / RADIUS_OF_EARTH) * Math.cos(lat),
    Math.cos(distance / RADIUS_OF_EARTH) - Math.sin(lat) * Math.sin(latR));

  return ({ lat: ToDegrees(latR), lng: ToDegrees(lngR) });
};

const validateLat = (lat) => {
  if (lat && lat !== '' && isNumber(lat) && lat >= -90 && lat <= 90) {
    return true;
  }

  return false;
};

const validateLong = (lon) => {
  if (lon && lon !== '' && isNumber(lon) && lon >= -180 && lon <= 180) {
    return true;
  }

  return false;
};

const validateX = (x) => {
  if (x && x !== '' && isNumber(x) && x >= 0 && x <= 131071) {
    return true;
  }

  return false;
};

const validateY = (y) => {
  if (y && y !== '' && isNumber(y) && y >= 0 && y <= 131071) {
    return true;
  }

  return false;
};

const validateSelectionArea = (minX, maxX, minY, maxY) => {
  if (minX === '' || maxX === '' || minY === '' || maxY === '') {
    return false;
  }

  // if (parseFloat(minX) === 0 && parseFloat(maxX) === 0 && parseFloat(minY) === 0 && parseFloat(maxY) === 0) {
  //   return false;
  // }

  if (!isNumber(minX) || !isNumber(maxX) || !isNumber(minY) || !isNumber(maxY)) {
    return false;
  }

  if (minX > maxX || minY > maxY) {
    return false;
  }

  return true;
};

const getTileCoordinatesMinMax = (x, y, z) => (
  [
    tile2long(x, z),
    tile2lat(y + 1, z),
    tile2long(x + 1, z),
    tile2lat(y, z),
  ]
);

const convertGCSZoom = (x, y, zoomFrom, zoomTo) => {
  if (zoomTo === zoomFrom) return { x, y };

  if (zoomTo < zoomFrom) {
    const resultX = ((x % 2 === 1) ? (x - 1) : x) / 2;
    const resultY = ((y % 2 === 1) ? (y - 1) : y) / 2;
    const currentZoom = zoomFrom - 1;
    if (currentZoom === zoomTo) return { x: resultX, y: resultY };
    return convertGCSZoom(resultX, resultY, currentZoom, zoomTo);
  }

  const squareSize = 2 ** (zoomTo - zoomFrom);
  const xTo = x * squareSize;
  const yTo = y * squareSize;
  return { x: xTo, y: yTo };
};

const getKeyForS3 = (x, y) => {
  const xy14 = convertGCSZoom(x, y, 17, 14);
  const xy12 = convertGCSZoom(xy14.x, xy14.y, 14, 12);
  const xy8 = convertGCSZoom(xy12.x, xy12.y, 12, 8);
  const path = `xy8=${xy8.x}-${xy8.y}/xy12=${xy12.x}-${xy12.y}/xy14=${xy14.x}-${xy14.y}`;
  return path;
};

const getBigSquares = (xArray, yArray, zoom, zoomBigSquares) => {
  const leftTop = { x: xArray[0], y: yArray[0] };
  const rightTop = { x: xArray[xArray.length - 1], y: yArray[0] };
  const leftBottom = { x: xArray[0], y: yArray[yArray.length - 1] };
  const rightBottom = { x: xArray[xArray.length - 1], y: yArray[yArray.length - 1] };
  const bigLeftTop = convertGCSZoom(leftTop.x, leftTop.y, zoom, zoomBigSquares);
  const bigRightTop = convertGCSZoom(rightTop.x, rightTop.y, zoom, zoomBigSquares);
  const bigLeftBottom = convertGCSZoom(leftBottom.x, leftBottom.y, zoom, zoomBigSquares);
  const bigRightBottom = convertGCSZoom(rightBottom.x, rightBottom.y, zoom, zoomBigSquares);
  const arrayOfBigSquares = [bigLeftTop, bigRightTop, bigLeftBottom, bigRightBottom];
  const arrayOfBigSquaresCleared = arrayOfBigSquares.reduce((obj, item) => {
    if (obj.findIndex((el) => el.x === item.x && el.y === item.y) === -1) return [...obj, item];
    return obj;
  }, []);
  return arrayOfBigSquaresCleared;
};

const getTileCoordinates = (x, y, z) => ({
  topLeft: {
    lng: tile2long(x, z),
    lat: tile2lat(y, z),
  },
  bottomRight: {
    lng: tile2long(x + 1, z),
    lat: tile2lat(y + 1, z),
  },
});

const getBboxCoordsForTurf = (square) => {
  const bbox = [0, 0, 0, 0];
  bbox[0] = square.topLeft.lng > square.bottomRight.lng ? square.bottomRight.lng : square.topLeft.lng;
  bbox[2] = square.topLeft.lng > square.bottomRight.lng ? square.topLeft.lng : square.bottomRight.lng;
  bbox[1] = square.topLeft.lat > square.bottomRight.lat ? square.bottomRight.lat : square.topLeft.lat;
  bbox[3] = square.topLeft.lat > square.bottomRight.lat ? square.topLeft.lat : square.bottomRight.lat;

  return bbox;
};

function getTimeStamp(delta) {
  return (Math.floor(new Date(new Date().toUTCString()) / 1000) + delta);
}

export {
  get8TilesAround, lon2tile, lat2tile, tile2long, tile2lat, validateLat, validateLong, getTileCoordinatesMinMax, validateX, validateY,
  destinationPoint, convertGCSZoom, getBigSquares, validateSelectionArea, getTileCoordinates, getBboxCoordsForTurf,
  getTimeStamp, getKeyForS3,
};
