import React, { useState } from 'react';
import { Router } from 'react-router-dom';
// import { makeStyles } from '@material-ui/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { default as Routes } from './routes';
import theme from './theme';
import configureStore from './store';
import { history } from './services';
import { Alert } from './components';

const store = configureStore();
const useStyles = makeStyles({
  success: {
    '& div': {
      margin: 'auto',
    },
  },
  error: {
    '& div': {
      margin: 'auto',
    },
  },
});

const App = () => {
  const classes = useStyles();
  const [hasTokens, setHasTokens] = useState(false);

  window.parent.postMessage(JSON.stringify({
    type: 'GET_TOKEN',
  }), '*');

  window.onmessage = (event) => {
    if (event.origin === process.env.REACT_APP_PARENT_URL) {
      try {
        const result = JSON.parse(event.data);

        if (result.type === 'SEND_TOKENS') {
          localStorage.setItem('accessToken', result.accessToken);
          localStorage.setItem('idToken', result.idToken);
          localStorage.setItem('groups', JSON.stringify(result.groups));

          if (!hasTokens) setHasTokens(true);
        }
      } catch (err) {
        console.log('error', err);
      }
    }
  };

  return hasTokens && (
    <Provider store={store}>

      <ThemeProvider theme={theme}>
        <SnackbarProvider
          classes={{
            variantSuccess: classes.success,
            variantError: classes.error,
            variantWarning: classes.warning,
            variantInfo: classes.info,
          }}
          hideIconVariant
        >
          <Router history={history}>
            <Alert />
            <Routes />
          </Router>
        </SnackbarProvider>
      </ThemeProvider>

    </Provider>
  );
};

export default App;
