import {
  post, history, setToken, removeToken,
} from '../../services';
import { setCurrentUserData, deleteCurrentUserData } from '../user/actions';
import { success, error } from '../alerts/actions';
import {
  LOGIN_USER, LOGOUT_USER, SHOW_SPINNER, SET_CUSTOM_TOKEN, SAVE_QR, SAVE_USERNAME, SAVE_PASSWORD, SAVE_CODE, SAVE_ACTION,
} from './types';

export function loginSuccess(loginData) {
  return {
    type: LOGIN_USER,
    payload: loginData,
  };
}

export function setCustomToken(data) {
  return {
    type: SET_CUSTOM_TOKEN,
    payload: data,
  };
}

export function saveQr(data) {
  return {
    type: SAVE_QR,
    payload: data,
  };
}

export function saveUsername(data) {
  return {
    type: SAVE_USERNAME,
    payload: data,
  };
}

export function savePassword(data) {
  return {
    type: SAVE_PASSWORD,
    payload: data,
  };
}

export function saveCode(data) {
  return {
    type: SAVE_CODE,
    payload: data,
  };
}

export function saveAction(data) {
  return {
    type: SAVE_ACTION,
    payload: data,
  };
}

export function spinnerVisibility(show) {
  return {
    type: SHOW_SPINNER,
    payload: show,
  };
}

export function logoutUser() {
  return {
    type: LOGOUT_USER,
  };
}

export function login(loginData) {
  return async (dispatch) => {
    try {
      dispatch(spinnerVisibility(true));
      const result = await post('authorization/login', loginData, false);

      dispatch(spinnerVisibility(false));
      if (!result) return;
      if (result.error) {
        dispatch(error(result.error));
        dispatch(spinnerVisibility(false));
        return;
      }

      if (result.qrCode) {
        dispatch(saveQr(result.qrCode));
        dispatch(saveUsername(loginData.username));
        dispatch(saveAction('mfa'));
        return;
      }

      if (result.result && result.result === 'success') {
        dispatch(saveAction('mfa'));
        dispatch(setCustomToken({ customToken: result.customToken }));
      }

      if (result.username) {
        const userData = {
          username: result.username,
          groups: result.groups,
        };
        setToken('accessToken', result.accessToken);
        setToken('idToken', result.idToken);
        setToken('groups', JSON.stringify(result.groups));
        dispatch(setCurrentUserData(userData));
        dispatch(success('You are successfully logged in'));
        dispatch(spinnerVisibility(false));
        dispatch(saveAction('login'));
        history.push('/');
      }
    } catch (e) {
      dispatch(error(e.message));
      dispatch(spinnerVisibility(false));
    }
  };
}

export function sendCode(codeData) {
  return async (dispatch) => {
    try {
      dispatch(spinnerVisibility(true));

      const result = await post('authorization/mfa', codeData, false);

      dispatch(spinnerVisibility(false));
      if (!result) {
        dispatch(error('Invalid code'));
        dispatch(spinnerVisibility(false));
        dispatch(logoutUser());
        return history.push('/login');
      }
      if (result.error) {
        dispatch(error(result.error));
        dispatch(spinnerVisibility(false));
        dispatch(logoutUser());
        return history.push('/login');
      }

      if (result.username) {
        const userData = {
          username: result.username,
          groups: result.groups,
        };
        setToken('accessToken', result.accessToken);
        setToken('idToken', result.idToken);
        setToken('groups', JSON.stringify(result.groups));
        dispatch(setCurrentUserData(userData));
        dispatch(success('You are successfully logged in'));
        dispatch(spinnerVisibility(false));
        dispatch(saveAction('login'));
        history.push('/');
      }
    } catch (e) {
      dispatch(error(e.message));
      dispatch(spinnerVisibility(false));
      history.push('/login');
    }
  };
}

export function changePassword(changePasswordData) {
  return async (dispatch) => {
    try {
      dispatch(spinnerVisibility(true));
      const result = await post('authorization/changePassword', changePasswordData, true);

      dispatch(spinnerVisibility(false));
      if (!result) return;
      if (result.error) {
        dispatch(error(result.error));
        dispatch(spinnerVisibility(false));
        return;
      }
      dispatch(success('Password was changed.'));
      history.push('/');
    } catch (e) {
      dispatch(error(e.message));
      dispatch(spinnerVisibility(false));
    }
  };
}

export function logout() {
  return async (dispatch, getState) => {
    const result = await post('authorization/signOut', { username: getState().user.username }, true);
    dispatch(spinnerVisibility(false));
    if (!result) return;
    if (result.error) {
      dispatch(error(result.error));
      dispatch(spinnerVisibility(false));
      return;
    }

    removeToken('accessToken');
    removeToken('idToken');
    dispatch(deleteCurrentUserData());
    dispatch(logoutUser());
    dispatch(success('You are successfully logged out'));
    history.push('/login');
  };
}
