import { v4 as uuidv4 } from 'uuid';
import * as turf from '@turf/turf';
import {
  get, post, remove,
} from '../../services';
import { error, success } from '../alerts/actions';
import types from './types';
import {
  lon2tile, lat2tile, get8TilesAround, tile2lat, tile2long, destinationPoint, getBigSquares, convertGCSZoom,
  getTileCoordinates, getBboxCoordsForTurf,
} from '../../helpers/gHelper';
import { centerCicleRadius } from '../../constants';

const currentKeyT = { value: undefined };
const currentKeyP = { value: undefined };
let cache = [];
const zoomBigDefault = 14; // please do not change this zoom level, because will be bugs in the preloading data after tiles/content api execution

export function updateLayers(source) {
  return {
    type: types.UPDATE_LAYERSSTATE,
    payload: source,
  };
}

export function addLayersDataTiles(data) {
  return {
    type: types.ADD_TILES,
    payload: data,
  };
}

export function clearTileDetails() {
  return {
    type: types.CLEAR_TILE_DETAILS,
  };
}

export function updateLocationByBigTile(data) {
  return {
    type: types.UPDATE_LOCATIONS_BY_BIG_TILE,
    payload: data,
  };
}

export function addLayersDataPoints(data) {
  return {
    type: types.ADD_POINTS,
    payload: data,
  };
}
export function showGenerationTilesAres(data) {
  return {
    type: types.SHOW_GENERATION_TILES_AREAS,
    payload: data,
  };
}

export function updateLayerDataContent(data) {
  return {
    type: types.UPDATE_CONTENT_LAYER,
    payload: data,
  };
}

export function setDataContentResult(data) {
  return {
    type: types.SET_CONTENT_RESULT,
    payload: data,
  };
}

export function setRandomLocationsResult(data) {
  return {
    type: types.SET_RANDOMLOCATIONS_RESULT,
    payload: data,
  };
}

export function setTileDetails(data) {
  return {
    type: types.SET_TILE_DETAILS,
    payload: data,
  };
}

export function setTilePoligonsDetails(data) {
  return {
    type: types.SET_TILE_POLIGONS_DETAILS,
    payload: data,
  };
}

export function clickOnMap(data) {
  return {
    type: types.CLICK_ON_MAP,
    payload: data,
  };
}

export function showSelectedArea(data) {
  return {
    type: types.SHOW_SELECTED_AREA,
    payload: data,
  };
}

export function removeSelectedArea() {
  return {
    type: types.REMOVE_SELECTED_AREA,
  };
}

export function updateTileDetails(data) {
  return {
    type: types.UPDATE_TILE_DETAILS,
    payload: data,
  };
}

export function setSelectedContentLocation(data) {
  return {
    type: types.SET_SELECTED_CONTENT_LOCATION,
    payload: data,
  };
}

export function setSelectedLocation(data) {
  return {
    type: types.SET_SELECTED_LOCATION,
    payload: data,
  };
}

export function setViewport(data) {
  return {
    type: types.SET_VIEWPORT,
    payload: data,
  };
}

export function setMarkerLocation(longitude, latitude, centerCicleValid) {
  return {
    type: types.SET_MARKER_LOCATION,
    payload: {
      latitude, longitude, centerCicleValid,
    },
  };
}

export function drawCircleForMarkerLocation(longitude, latitude) {
  return {
    type: types.DRAW_CIRCLE_FOR_MARKERLOCATION,
    payload: {
      latitude, longitude,
    },
  };
}

export function setMarkerLocationWithValidation(longitude, latitude) {
  return async (dispatch) => {
    const gcs = {
      x: lon2tile(longitude, 17),
      y: lat2tile(latitude, 17),
    };
    const tileCor = getTileCoordinates(gcs.x, gcs.y, 17);
    const bboxTile = getBboxCoordsForTurf(tileCor);
    const center = turf.point([(bboxTile[2] + bboxTile[0]) / 2, (bboxTile[3] + bboxTile[1]) / 2]);
    const centerCicle = turf.circle(center, centerCicleRadius, { steps: 7, units: 'meters' });
    const points = turf.points([[longitude, latitude]]);
    const ptsWithin = turf.pointsWithinPolygon(points, centerCicle);
    dispatch(setMarkerLocation(longitude, latitude, ptsWithin.features.length === 0));
  };
}

export function showHideMarkerLocation(show) {
  return {
    type: types.SHOW_HIDE_MARKER_LOCATION,
    payload: { show },
  };
}

export function setViewportLatitude(data) {
  return {
    type: types.SET_VIEWPORT_LATITUDE,
    payload: data,
  };
}

export function clearExpiredContent() {
  return {
    type: types.CLEAR_EXPIRED_CONTENT,
  };
}

export function loadingMapRemove(data) {
  return {
    type: types.LOADING_MAP_REMOVE,
    payload: data,
  };
}

export function loadingMapAdd(data) {
  return {
    type: types.LOADIND_MAP_ADD,
    payload: data,
  };
}

export function setViewportLongitude(data) {
  return {
    type: types.SET_VIEWPORT_LONGITUDE,
    payload: data,
  };
}

export function setViewportX(data) {
  return {
    type: types.SET_VIEWPORT_X,
    payload: data,
  };
}

export function setViewportY(data) {
  return {
    type: types.SET_VIEWPORT_Y,
    payload: data,
  };
}

export function setViewportZoom(data) {
  return {
    type: types.SET_VIEWPORT_ZOOM,
    payload: data,
  };
}

export function setTilesInfo(data) {
  return {
    type: types.SET_TILES_INFO,
    payload: data,
  };
}

export function showGeneratedAreas(data) {
  return {
    type: types.SHOW_GENERATED_AREAS,
    payload: data,
  };
}

export function loadTiles(coords) {
  return async (dispatch) => {
    try {
      const result = await get('tiles/maptiles', coords, true);
      if (!result) return;
      if (result.error) {
        dispatch(error(result.error));
        return;
      }
      if (result.features.length > 0) dispatch(addLayersDataTiles(result));
    } catch (e) {
      dispatch(error(`Failed to load tiles.${e}`));
    }
  };
}

export function showTileDetails(gcs, level, main) {
  return async (dispatch) => {
    try {
      const result = await get('tile/details', gcs, true);
      if (!result) return;
      if (result.error) {
        dispatch(error(result.error));
        return;
      }
      const { proportionsInfo: prInfo, additionalSquarePoligons, poligonsFeatures } = result;
      const original = { main, level };
      const details = {
        main,
        level,
        prInfo,
        additionalSquarePoligons,
        gcs,
        original,
      };

      dispatch(setTileDetails(details));

      dispatch(setTilePoligonsDetails(poligonsFeatures));
    } catch (e) {
      dispatch(error('Failed on getting tiles details.'));
    }
  };
}

export function addLocation(main, latitude, longitude) {
  return async (dispatch) => {
    try {
      const json = JSON.stringify({
        main, latitude, longitude,
      });
      const result = await post('/tiles/location/', json, true);
      if (!result) return;
      if (result.error) {
        dispatch(error(result.error));
        return;
      }
      dispatch(showHideMarkerLocation(false));
      const gcs = {
        x: lon2tile(longitude, 17),
        y: lat2tile(latitude, 17),
      };
      const gcs14 = convertGCSZoom(gcs.x, gcs.y, 17, 14);

      dispatch(updateLocationByBigTile({
        gcs14,
        locations: result,
      }));
      dispatch(success('Location was added.'));
    } catch (e) {
      console.log(e);
      dispatch(error(`${e}`));
      dispatch(error('Failed adding location.'));
    }
  };
}

export function onSaveTileDetails(tileDetailsState) {
  return async (dispatch) => {
    try {
      const json = JSON.stringify({
        x: tileDetailsState.gcs.x,
        y: tileDetailsState.gcs.y,
        main: tileDetailsState.main,
        level: tileDetailsState.level,
      });
      const result = await post('tile/update', json, true);
      if (!result) return;
      if (result.error) {
        dispatch(error(result.error));
        return;
      }
      dispatch(updateTileDetails({
        main: result.main,
        level: result.level,
        color: result.color,
        gcs: tileDetailsState.gcs,
      }));
      dispatch(setTileDetails({ ...tileDetailsState, original: { main: result.main, level: result.level } }));
      dispatch(success('Tile was updated.'));
    } catch (e) {
      console.log(e);
      dispatch(error('Failed on updating tiles details.'));
    }
  };
}

export function removeLocation(gcs, coordinates) {
  return async (dispatch) => {
    try {
      const json = JSON.stringify({
        gcs, coordinates,
      });
      const result = await remove('/tiles/location/', json, true);
      if (!result) return;
      if (result.error) {
        dispatch(error(result.error));
        return;
      }
      const gcs14 = convertGCSZoom(gcs.x, gcs.y, 17, 14);

      dispatch(updateLocationByBigTile({
        gcs14,
        locations: result,
      }));
      dispatch(setSelectedLocation(undefined));
      dispatch(success('Location was removed.'));
    } catch (e) {
      console.log(e);
      dispatch(error('Failed on removing location.'));
    }
  };
}

export function removeFromCache(array) {
  return async (dispatch) => {
    try {
      cache = cache.filter((el) => array.findIndex((cord) => el.x === cord.x && el.y === cord.y && !el.dataExist) === -1);
    } catch (e) {
      dispatch(error('Failed on updating tiles details.'));
    }
  };
}

async function uploadToLayer(zoomFinal, zoomBig, cord, type, dispatch) {
  const degree = zoomFinal - zoomBig;
  const param = (2 ** degree);
  if (!cache.find((el) => el.x === cord.x && el.y === cord.y && el.type === type)) {
    // setLoading(true);
    const yBigBottomRight = cord.y + 1;
    const xBigBottomRight = cord.x + 1;

    const cacheItem = {
      x: cord.x, y: cord.y, type, dataExist: false,
    };
    cache = [...cache, cacheItem];

    // setCache(cacheNew);
    const coords = {
      minX: cord.x * param,
      minY: cord.y * param,
      maxX: xBigBottomRight * param,
      maxY: yBigBottomRight * param,
    };

    switch (type) {
      case 'points': {
        const result = await get('tiles/maplocations', coords, true);
        if (!result) return;
        if (result.error) {
          dispatch(error(result.error));
          return;
        }

        if (result.length > 0) {
          cacheItem.dataExist = true;
          dispatch(addLayersDataPoints({ locations: result, gcs14: cord }));
        }
        break;
      }
      case 'tiles': {
        const result = await get('tiles/maptiles', coords, true);
        if (!result) return;
        if (result.error) {
          dispatch(error(result.error));
          return;
        }
        if (result.features.length > 0) {
          cacheItem.dataExist = true;
          dispatch(addLayersDataTiles(result));
        }
        break;
      }
      default:
        break;
    }
    // setLoading(false);
  }
}

async function checkTile(zoomFinal, zoomBig, coords, loopKey, index, type, dispatch) {
  await uploadToLayer(zoomFinal, zoomBig, coords[index], type, dispatch);
  index += 1;
  let staticCurrentKey;
  switch (type) {
    case 'points': {
      staticCurrentKey = currentKeyP.value;
      break;
    }
    case 'tiles': {
      staticCurrentKey = currentKeyT.value;
      break;
    }
    default:
      break;
  }

  if (loopKey === staticCurrentKey && coords.length > index) {
    const result = await checkTile(zoomFinal, zoomBig, coords, loopKey, index, type, dispatch);
    return result;
  } dispatch(loadingMapRemove(loopKey));

  return true;
}

export function downloadTilesLatLon(lat, lon, currentZoom) {
  return async (dispatch) => {
    try {
      if (currentZoom >= 12) {
        await downloadByType('tiles', lat, lon, dispatch);
      }
      if (currentZoom > 15) {
        await downloadByType('points', lat, lon, dispatch);
      }
    } catch (e) {
      console.log(e);
      dispatch(error('Failed on updating tiles.'));
    }
  };
}

// x, y in zoom by default17
export function downloadTilesByXY(x, y, currentZoom, zoomForXY = 17) {
  const lat = tile2lat(y, zoomForXY);
  const lng = tile2long(x, zoomForXY);
  const coords = destinationPoint({ lat, lng }, 5, -225);
  downloadTilesLatLon(coords.lat, coords.lng, currentZoom);
}

/// type can be 'tiles', 'points'
async function downloadByType(type, lat, lon, dispatch) {
  let zoomFinal;
  const zoomBig = zoomBigDefault;
  let premiterSize;
  let loopKey;
  switch (type) {
    case 'points': {
      zoomFinal = 17;
      premiterSize = [3];
      currentKeyP.value = uuidv4();
      loopKey = currentKeyP.value;
      break;
    }
    case 'tiles': {
      zoomFinal = 17;
      premiterSize = [3, 5, 7];
      currentKeyT.value = uuidv4();
      loopKey = currentKeyT.value;
      break;
    }
    default:
      break;
  }

  const yBigTopLeft = lat2tile(lat, zoomBig);
  const xBigTopLeft = lon2tile(lon, zoomBig);

  const center = { x: xBigTopLeft, y: yBigTopLeft };
  let coords = get8TilesAround(center, premiterSize);
  coords = [center, ...coords];

  dispatch(loadingMapAdd(loopKey));
  await checkTile(zoomFinal, zoomBig, coords, loopKey, 0, type, dispatch);
}

export function testTilesInfo(tilesInfoCoordinates) {
  return async (dispatch, getState) => {
    try {
      const result = await post('/tiles/info', tilesInfoCoordinates, true);

      if (!result) return;
      if (result.error) {
        dispatch(error(result.error));
        return;
      }

      dispatch(setTilesInfo({ result }));

      const gcs = {
        x: lon2tile(tilesInfoCoordinates.lon, 17),
        y: lat2tile(tilesInfoCoordinates.lat, 17),
      };

      const arrayOfBigSquares = getBigSquares([gcs.x - 3, gcs.x + 3], [gcs.y - 3, gcs.y + 3], 17, 14);
      dispatch(removeFromCache(arrayOfBigSquares)).then(() => {
        const mapBoxState = getState().mapbox.viewport;
        dispatch(downloadTilesLatLon(mapBoxState.latitude, mapBoxState.longitude, mapBoxState.zoom));
      });
    } catch (e) {
      console.log(e);
      dispatch(error(`Failed.${e}`));
    }
  };
}
