import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
  InputLabel, FormControlLabel,
  TextField, Grid, Button, Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ReactJson from 'react-json-view';
import { testRandomLocations } from '../../../store/generate/actions';

import {
  clearExpiredContent,
} from '../../../store/mapbox/actions';

import {
  validateX, validateY,
} from '../../../helpers/gHelper';

const squareSizeOptions = [1, 3, 5, 7];
const elementTypes = [{ id: 'water', name: 'water' }, { id: 'air', name: 'air' }, { id: 'forest', name: 'forest' }, { id: 'ground', name: 'ground' }];

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  textField: {
    width: '100%',
    padding: '0 2px',
  },

  button: {
    width: '100%',
    margin: '8px 0',
  },
  txTilesGeneration: {
    marginTop: 5,
    marginBottom: 5,
  },
}));

const RandomLocations = ({
  randomLocationsResult, selectedPlaceOnMap,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [stateRandomLocations, setStateRandomLocations] = React.useState(
    {
      allowedElementTypes: elementTypes.map((el) => el),
      count: 5,
      gcsCenter: { x: 74626, y: 37941 },
      squareSize: 7,
      duration: { min: 5, max: 15 },
    },
  );
  const [valueTilesContent, setTilesContentValue] = React.useState({});

  useEffect(() => {
    if (selectedPlaceOnMap) setStateRandomLocations((prevStateRandomLocations) => ({ ...prevStateRandomLocations, gcsCenter: selectedPlaceOnMap.gcs }));
  }, [selectedPlaceOnMap]);

  const handleChangeSquareSize = (e) => {
    setStateRandomLocations({ ...stateRandomLocations, squareSize: e.target.value });
  };

  const handleChangeCenterCoords = (e, type) => {
    switch (type) {
      case 'x':
        setStateRandomLocations({
          ...stateRandomLocations,
          gcsCenter: {
            ...stateRandomLocations.gcsCenter,
            x: parseInt(e.target.value, 10) > 0 ? parseInt(e.target.value, 10) : 0,
          },
        });
        break;
      case 'y':
        setStateRandomLocations({
          ...stateRandomLocations,
          gcsCenter: {
            ...stateRandomLocations.gcsCenter,
            y: parseInt(e.target.value, 10) > 0 ? parseInt(e.target.value, 10) : 0,
          },
        });
        break;
      default:
        break;
    }
  };

  const handleChangeCount = (e) => {
    setStateRandomLocations({
      ...stateRandomLocations,
      count: e.currentTarget.value > 0 ? parseInt(e.currentTarget.value, 10) : 0,
    });
  };

  const handleTestButton = () => {
    console.log(stateRandomLocations.gcsCenter);
    dispatch(testRandomLocations(valueTilesContent, stateRandomLocations.gcsCenter));
  };

  const handleChangeDuration = (e, key) => {
    switch (key) {
      case 'min':
        setStateRandomLocations({
          ...stateRandomLocations,
          duration: {
            ...stateRandomLocations.duration,
            min: e.currentTarget.value > 0 ? parseInt(e.currentTarget.value, 10) : 0,
          },
        });
        break;
      case 'max':
        setStateRandomLocations({
          ...stateRandomLocations,
          duration: {
            ...stateRandomLocations.duration,
            max: e.currentTarget.value > 0 ? parseInt(e.currentTarget.value, 10) : 0,
          },
        });
        break;
      default:
        break;
    }
  };

  const handleChangeAllowedElementTypes = (e, id) => {
    const { checked } = e.target;
    if (checked) {
      const elType = elementTypes.find((el) => el.id === id);
      setStateRandomLocations({
        ...stateRandomLocations,
        allowedElementTypes: [...stateRandomLocations.allowedElementTypes, elType],
      });
    } else {
      const elTypeIndex = stateRandomLocations.allowedElementTypes.findIndex((el) => el.id === id);
      const copy = [...stateRandomLocations.allowedElementTypes];
      copy.splice(elTypeIndex, 1);
      setStateRandomLocations({
        ...stateRandomLocations,
        allowedElementTypes: copy,
      });
    }
  };

  const onClearExpiredContent = () => {
    dispatch(clearExpiredContent());
  };

  const buildTilesContentJson = useCallback(() => {
    let contentTilesArray = [];
    for (let x = stateRandomLocations.gcsCenter.x - ((stateRandomLocations.squareSize - 1) / 2); x <= ((stateRandomLocations.squareSize - 1) / 2) + stateRandomLocations.gcsCenter.x; x += 1) {
      for (let y = stateRandomLocations.gcsCenter.y - ((stateRandomLocations.squareSize - 1) / 2); y <= ((stateRandomLocations.squareSize - 1) / 2) + stateRandomLocations.gcsCenter.y; y += 1) {
        contentTilesArray = [...contentTilesArray, { x, y }];
      }
    }
    const defaultTilesContentValueT = {
      allowedElementTypes: stateRandomLocations.allowedElementTypes.map((el) => el.name),
      count: stateRandomLocations.count,
      duration: (stateRandomLocations.duration.min === 0 && stateRandomLocations.duration.max === 0) ? undefined : stateRandomLocations.duration,
      tiles: contentTilesArray,
    };
    setTilesContentValue(defaultTilesContentValueT);
  }, [stateRandomLocations]);

  useEffect(() => {
    buildTilesContentJson();
  }, [stateRandomLocations, buildTilesContentJson]);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={6} className={classes.txTilesGeneration} style={{ paddingRight: 2 }}>
          <TextField
            style={{ width: '100%' }}
            id="coords-x"
            label="X center"
            type="number"
            value={stateRandomLocations.gcsCenter.x}
            onChange={(e) => { handleChangeCenterCoords(e, 'x'); }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} className={classes.txTilesGeneration} style={{ paddingLeft: 2 }}>
          <TextField
            style={{ width: '100%' }}
            id="coords-y"
            label="Y center"
            type="number"
            className={classes.fullWidth}
            value={stateRandomLocations.gcsCenter.y}
            onChange={(e) => { handleChangeCenterCoords(e, 'y'); }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} className={classes.txTilesGeneration} style={{ paddingLeft: 2 }}>
          <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
            <InputLabel>Square Size</InputLabel>
            <Select
              id="select-square-size"
              value={stateRandomLocations.squareSize}
              onChange={handleChangeSquareSize}
              label="Square Size"
            >
              {squareSizeOptions.map((number) => (<MenuItem key={number} value={number}>{number}</MenuItem>)) }

            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} className={classes.txTilesGeneration} style={{ paddingLeft: 2 }}>
          <TextField
            style={{ width: '100%' }}
            id="standard-number-count"
            label="Count"
            type="number"
            value={stateRandomLocations.count}
            onChange={(e) => { handleChangeCount(e); }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} className={classes.txTilesGeneration} style={{ paddingLeft: 2 }}>
          <TextField
            style={{ width: '100%' }}
            id="duration-min"
            label="Duration Min"
            type="number"
            value={stateRandomLocations.duration.min}
            onChange={(e) => { handleChangeDuration(e, 'min'); }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} className={classes.txTilesGeneration} style={{ paddingLeft: 2 }}>
          <TextField
            style={{ width: '100%' }}
            id="duration-max"
            label="Duration Max"
            type="number"
            value={stateRandomLocations.duration.max}
            onChange={(e) => { handleChangeDuration(e, 'max'); }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid container item xs={12}>
          {elementTypes.map((type) => (
            <Grid container item xs={6}>
              <FormControlLabel
                control={<Checkbox checked={stateRandomLocations.allowedElementTypes.some((el) => el.id === type.id)} onChange={(e) => { handleChangeAllowedElementTypes(e, type.id); }} />}
                label={type.name}
              />
            </Grid>
          ))}
        </Grid>

        <Grid container>
          <ReactJson
            style={{
              border: '1px solid rgba(0, 0, 0, 0.23)', width: '100%', borderRadius: 4, padding: 4, marginTop: 5, marginBottom: 5,
            }}
            name={false}
            src={valueTilesContent}
            iconStyle="square"
            displayDataTypes={false}
            displayObjectSize={false}
            collapsed="1"
            onChange={(e) => { console.log(e); }}
          />
        </Grid>
        <Grid container>
          <Button
            variant="contained"
            className={classes.button}
            style={{ width: '100%' }}
            color="primary"
            onClick={handleTestButton}
            disabled={!validateX(stateRandomLocations.gcsCenter.x) || !validateY(stateRandomLocations.gcsCenter.y) || !stateRandomLocations.allowedElementTypes.length > 0 || !stateRandomLocations.count}
          >
            Test
          </Button>
          <Button
            variant="contained"
            className={classes.txTilesGeneration}
            style={{ width: '100%' }}
            color="primary"
            onClick={onClearExpiredContent}
          >
            Clear expired content
          </Button>
        </Grid>
        {randomLocationsResult
            && (
              <Grid container>

                <p>Result:</p>
                <ReactJson
                  style={{
                    border: '1px solid rgba(0, 0, 0, 0.23)', width: '100%', borderRadius: 4, padding: 4, marginTop: 5, marginBottom: 5,
                  }}
                  name={false}
                  src={randomLocationsResult}
                  iconStyle="square"
                  displayDataTypes={false}
                  displayObjectSize={false}
                  collapsed="1"
                />
              </Grid>
            )}
      </Grid>
    </div>
  );
};

RandomLocations.propTypes = {
  randomLocationsResult: PropTypes.shape(),
  selectedPlaceOnMap: PropTypes.shape(),
};

RandomLocations.defaultProps = {
  randomLocationsResult: undefined,
  selectedPlaceOnMap: undefined,
};

const mapStateToProps = (state) => ({
  randomLocationsResult: state.mapbox.randomLocationsResult,
  selectedPlaceOnMap: state.mapbox.selectedPlaceOnMap,

});

export default connect(mapStateToProps)(RandomLocations);
