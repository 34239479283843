import * as moment from 'moment';
import AlertTypes from './types';

export function setAlert(alert) {
  return {
    type: AlertTypes.SET_ALERT,
    payload: alert,
  };
}

export function deleteAlert(key) {
  return {
    type: AlertTypes.DELETE_ALERT,
    payload: key,
  };
}

export function clearAlert() {
  return {
    type: AlertTypes.CLEAR_ALERT,
  };
}

export function success(message) {
  return async (dispatch) => {
    const alert = {
      type: 'success',
      message,
      key: moment.utc().unix(),
    };
    dispatch(setAlert(alert));
  };
}

const sendAlert = (message, type, dispatch) => {
  let text = null;
  if (typeof message === 'object') {
    if (Object.prototype.hasOwnProperty.call(message, 'msg') && Object.prototype.hasOwnProperty.call(message, 'param')) {
      text = `${message.param}: ${message.msg}`;
    }
  } else {
    text = message;
  }

  if (text) {
    const alert = {
      type,
      message: text,
      key: moment.utc().unix() + (Math.floor(Math.random() * 10) + 1),
    };

    dispatch(setAlert(alert));
  }
};

export function error(message) {
  return async (dispatch) => {
    if (Array.isArray(message)) {
      message.forEach((msgText) => sendAlert(msgText, 'error', dispatch));
    } else {
      sendAlert(message, 'error', dispatch);
    }
  };
}

export function remove(key) {
  return async (dispatch) => {
    dispatch(deleteAlert(key));
  };
}

export function clear() {
  return async (dispatch) => {
    dispatch(clearAlert());
  };
}
