import {
  put, getToken, history,
} from '../../services';
import { error, success } from '../alerts/actions';
import types from './types';

export function settingsRequest() {
  return {
    type: types.FETCH_SETTINGS_REQUEST,
  };
}

export function settingsLoaded(settings) {
  return {
    type: types.FETCH_SETTINGS_SUCCESS,
    payload: settings,
  };
}

export function getSettings() {
  return async (dispatch) => {
    return false;
  };
}

export function saveSettings(kpis) {
  return async (dispatch) => {
    try {
      dispatch(settingsRequest);
      const options = {
        idToken: getToken('idToken'),
      };
      const json = { dashboards: kpis };
      const result = await put('settings/', json, true, options);
      if (!result) return;
      if (result.error) {
        dispatch(error(result.error));
        return;
      }

      dispatch(settingsLoaded(result.dashboards));
      dispatch(success('Settings were saved.'));
      history.push('/');
    } catch (e) {
      dispatch(error('Failed to save list of settings'));
    }
  };
}
