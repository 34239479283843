import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
} from '@material-ui/core';
import {
  Formik, Form, Field,
} from 'formik';

import { TextField } from 'formik-material-ui';

import {
  login as loginAction, sendCode as sendCodeAction, saveCode,
} from '../../store/auth/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentBody: {
    flexGrow: 0.6,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    padding: '0 100px',
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
    height: '70px',
  },
  signInButton: {
    margin: theme.spacing(2, 0),
    textTransform: 'inherit',
  },
  buttonProgress: {
    color: theme.palette.primary.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: '8px 0',
    position: 'relative',
  },
  qrCode: {
    minWidth: '360px',
  },
}));

const QrCode = ({
  dispatch, showSpinner, qrCode, username, password,
}) => {
  const classes = useStyles();

  const showQr = qrCode ? (
    <Typography>
      <img alt="qrCode" className={classes.qrCode} src={qrCode} />
    </Typography>
  ) : null;
  return (

    <div className={classes.contentBody}>
      <Formik
        initialValues={{
          code: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.code) {
            errors.username = 'Code is required';
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          const requestData = {
            code: values.code,
            username,
            password,
            action: 'mfa',
          };

          dispatch(saveCode(values.code));

          setTimeout(() => {
            setSubmitting(false);
            if (qrCode) {
              dispatch(sendCodeAction(requestData));
            } else {
              dispatch(loginAction(requestData));
            }
          }, 1);
        }}
      >

        {({
          submitForm, isSubmitting, errors, touched,
        }) => (
          <Form>
            {showQr}
            <Field
              component={TextField}
              className={classes.textField}
              fullWidth
              label="Code"
              name="code"
              type="text"
              variant="outlined"
              error={false}
            />

            <div className={classes.wrapper}>
              <Button
                className={classes.signInButton}
                color="primary"
                fullWidth
                size="large"
                type="button"
                variant="contained"
                disabled={isSubmitting || showSpinner}
                onClick={submitForm}
              >
                Login
              </Button>
              {showSpinner && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </Form>
        )}
      </Formik>
    </div>

  );
};

QrCode.propTypes = {
  dispatch: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool.isRequired,
//   qrCode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  showSpinner: state.auth.showSpinner,
  qrCode: state.auth.qrCode,
  username: state.auth.username,
  password: state.auth.password,
});

export default connect(mapStateToProps)(QrCode);
