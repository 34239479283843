import React from 'react';

import { useDispatch } from 'react-redux';
import * as MUI from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  usePagination, useTable,
} from 'react-table';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { Delete, Info } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import SmartTooltip from 'react-smart-tooltip';
import { deleteRequest, setListPage } from '../../../store/generate/actions';
import TablePaginationActions from '../../General/Tables/TablePaginationActions';

const useStyles = makeStyles((theme) => ({
  tableRoot: {
    width: '100%',
    fontSize: '10px',
    '& td': {
      fontSize: '12px',
      padding: '0 4px 0 4px',
    },
  },
  grid: {
    height: '100%',
  },
  root: {
    fontSize: '12px',
    '& tr': {
      '& th': {
        minWidth: 50,
        height: 10,
        fontWeight: 'bold',
        verticalAlign: 'baseline',
        borderBottom: '1px solid #b5b5b5',
        padding: '0 4px 0 4px',
        fontSize: '12px',

      },
    },
  },
  actionActiveElementColor: {
    color: theme.palette.primary.dark,
  },
  actionDisabledElementColor: {
    color: theme.palette.primary.light,
  },
}));

const List = ({
  data, listPage,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const presentRegenerationConfig = (config) => {
    let result = '';
    if (config && config.updateLevels) {
      if (config.updateLevels.regenerateAutoGenerated) result += ' - Regenerate auto generated levels \n';
      if (config.updateLevels.regenerateManualAdded) result += ' - Regenerate manually updated levels \n';

      if (config.updateMainElement.regenerateAutoGenerated) result += ' - Regenerate auto generated main \n';
      if (config.updateMainElement.regenerateManualAdded) result += ' - Regenerate manually updated main \n';

      if (config.updateLocations.regenerateAutoGenerated) result += ' - Regenerate locations in auto generated locations in tiles \n';
      if (config.updateLocations.regenerateManualAdded) result += ' - Regenerate locations in manually updated locations in tiles \n';
    }
    if (result === '') return 'no one options were selected';
    return result;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Created At',

        Cell: (row) => (
          <div style={{ width: 67 }}>
            {row.row.original.date ? moment.unix(row.row.original.date).format('DD-MM-YYYY HH:mm:ss') : null}
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'state',
      },
      {
        Header: 'Username',
        Cell: (row) => (
          <div style={{ width: 100 }}>
            <SmartTooltip text={row.row.original.username} />
          </div>
        ),
      },
      {
        Header: 'Area',
        disableSortBy: true,
        Cell: (row) => (
          <ul style={{ listStyleType: 'none' }}>
            {
            row.row.original.area ? Object.keys(row.row.original.area).map((key) => (
              <li key={key}>
                {key}
                =
                {row.row.original.area[key]}
              </li>
            )) : null
            }
          </ul>
        ),
      },
      {
        Header: 'Info',
        Cell: (row) => (
          <div>
            <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{presentRegenerationConfig(row.row.original.updateConfig)}</div>}>
              <Info className={classes.actionActiveElementColor} />
            </Tooltip>
          </div>
        ),
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableSortBy: true,
        Cell: (row) => (

          ['processed', 'error'].includes(row.row.original.state)
            ? (
              <div>
                <IconButton
                  onClick={() => {
                    dispatch(deleteRequest(row.row.original.id));
                  }}
                  style={{ cursor: 'pointer' }}
                  title="Delete"
                >
                  <Delete className={classes.actionActiveElementColor} />
                </IconButton>
              </div>
            )
            : null
        ),
      },
    ],
    [dispatch, classes],
  );

  const {
    getTableProps, headerGroups, prepareRow, page,
    gotoPage, setPageSize,
    state: {
      pageIndex, pageSize,
    },

  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10, pageIndex: listPage },
    },
    usePagination,
  );

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
    dispatch(setListPage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  return (
    <MUI.TableContainer className={classes.tableRoot}>

      <MUI.Table {...getTableProps()}>
        <MUI.TableHead className={classes.root}>
          {headerGroups.map((headerGroup) => (
            <MUI.TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <MUI.TableCell
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </MUI.TableCell>
              ))}
            </MUI.TableRow>
          ))}
        </MUI.TableHead>
        <MUI.TableBody>
          {page.map((row) => {
            prepareRow(row);
            return (
              <MUI.TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <MUI.TableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </MUI.TableCell>
                ))}
              </MUI.TableRow>
            );
          })}
        </MUI.TableBody>
        <MUI.TableFooter>
          <MUI.TableRow>
            <MUI.TablePagination
              rowsPerPageOptions={[
                5,
                10,
                25,
                { label: 'All', value: data.length },
              ]}
              colSpan={10}
              count={data.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </MUI.TableRow>
        </MUI.TableFooter>
      </MUI.Table>

    </MUI.TableContainer>
  );
};

List.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  listPage: PropTypes.number,
};

List.defaultProps = {
  listPage: [],
};

export default List;
