import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import SidebarTools from '../../components/Mapbox/SidebarTools';
import { Topbar } from './components';
import { CoverSpinner } from '../../components';
import { getSettings } from '../../store/settings/actions';
import { resetSelectionAria } from '../../store/generate/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    // paddingLeft: 240,
  },
  content: {
    height: '100%',
    '& div': {
      padding: 0,
      '& div': {
        margin: 0,
      },
    },
  },
}));

const Main = (props) => {
  const { children } = props;

  const classes = useStyles();
  const isDesktop = false;/* useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  }); */
  const dispatch = useDispatch();
  const [openSidebar, setOpenSidebar] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleSidebarOpen = () => {
    if (openSidebar) {
      dispatch(resetSelectionAria());
    }
    setOpenSidebar(!openSidebar);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getSettings());
    setLoading(false);
  }, [dispatch]);

  const shouldOpenSidebar = isDesktop ? true : openSidebar;
  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <SidebarTools
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant="persistent"
      />
      <main className={classes.content} style={{ overflow: 'hidden', marginRight: shouldOpenSidebar ? 733 : 0 }}>
        {children}
      </main>
      <CoverSpinner show={loading} />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(Main);
