import * as turf from '@turf/turf';
import {
  post, get, remove,
} from '../../services';
import { error, success } from '../alerts/actions';
import {
  removeFromCache, showGeneratedAreas, updateLayerDataContent, setDataContentResult, setRandomLocationsResult, downloadTilesLatLon,
} from '../mapbox/actions';
import * as gHelper from '../../helpers/gHelper';
import types from './types';

const colors = {
  forest: 'rgba(40, 167, 69, 1)', // green
  ground: 'rgba(255, 193, 7, 1)', // yellow
  air: 'rgba(150, 54, 148, 1)', //  Medium Red Violet
  water: 'rgba(0, 123, 255, 1)', // blue
};

export function setSelectionAria(data) {
  return {
    type: types.UPDATE_SELECTION_AREA,
    payload: data,
  };
}
export function addPointToSelectionAria(point) {
  return {
    type: types.ADD_POINT_SELECTION_AREA,
    payload: point,
  };
}
export function resetSelectionAria() {
  return {
    type: types.RESET_SELECTION_AREA,
  };
}

export function generationListLoaded(data) {
  return {
    type: types.FETCH_LIST_SUCCESS,
    payload: data,
  };
}

export function deleteRequestAction(id) {
  return {
    type: types.FETCH_DELETE_REQUEST,
    payload: id,
  };
}
export function addToList(data) {
  return {
    type: types.ADD_TO_LIST,
    payload: data,
  };
}

export function setListPage(data) {
  return {
    type: types.SET_LIST_PAGE,
    payload: data,
  };
}

export function generatedAreasLoaded(data) {
  return {
    type: types.FETCH_AREAS_SUCCESS,
    payload: data,
  };
}
export function addZoomLoadedAreas(zoom) {
  return {
    type: types.ADD_ZOOM_LOADED_GENERATED_AREAS,
    payload: zoom,
  };
}

export function getGenerationList() {
  return async (dispatch, getState) => {
    try {
      const result = await get('/tiles/generation/', null, true);
      if (!result) return;
      if (result.error) {
        dispatch(error(result.error));
        return;
      }
      getState().generate.list.forEach((requestInState) => {
        if (['pending', 'in progress'].includes(requestInState.state)) {
          const requestInResult = result.find((r) => r.id === requestInState.id);
          if (requestInResult && ['processed', 'error'].includes(requestInResult.state)) {
            const minX = gHelper.lon2tile(gHelper.tile2long(requestInResult.area.minx, 17), 14);
            const maxX = gHelper.lon2tile(gHelper.tile2long(requestInResult.area.maxX, 17), 14);

            const minY = gHelper.lat2tile(gHelper.tile2lat(requestInResult.area.minY, 17), 14);
            const maxY = gHelper.lat2tile(gHelper.tile2lat(requestInResult.area.maxY, 17), 14);
            for (let x = minX; x <= maxX; x++) {
              for (let y = minY; y <= maxY; y++) {
                dispatch(removeFromCache([{ x, y }]));
              }
            }
          }
        }
      });

      dispatch(generationListLoaded(result));
    } catch (e) {
      console.log(e);
      dispatch(error(`Generation failed.${e}`));
    }
  };
}

export function deleteRequest(id) {
  return async (dispatch) => {
    try {
      const result = await remove(`/tiles/generation/${id}`, null, true);
      if (!result) return;
      dispatch(deleteRequestAction(id));
    } catch (e) {
      dispatch(error('Failed to delete request'));
    }
  };
}

export function generate(minX, minY, maxX, maxY, updateConfig) {
  return async (dispatch) => {
    try {
      const json = {
        minX, minY, maxX, maxY, updateConfig,
      };
      console.log(updateConfig);
      const result = await post('/tiles/generation/', json, true);
      if (!result) return;
      if (result.error) {
        dispatch(error(result.error));
        return;
      }

      dispatch(addToList(result.result));
      const arrayOfBigSquares = gHelper.getBigSquares([minX - 64, maxX + 64], [minY - 64, maxY + 64], 17, 14);
      dispatch(removeFromCache(arrayOfBigSquares));

      dispatch(success('Success, you will be notified by email when the generation finished.'));
    } catch (e) {
      console.log(e);
      dispatch(error(`Generation failed.${e}`));
    }
  };
}

export function testTilesContentValue(valueTilesContent) {
  return async (dispatch, getState) => {
    try {
      const json = valueTilesContent;
      const result = await post('/tiles/content', json, true);
      if (!result) return;
      if (result.error) {
        console.log(error);
        dispatch(error(result.error));
        return;
      }
      const locations = result.reduce((obj, el) => {
        const point = turf.point(el.location);
        const lineItemElementType = turf.buffer(point, 0.005, { units: 'kilometers' });
        lineItemElementType.properties = { color: colors[el.itemElementType], data: el };
        const lineOriginalPoligon = turf.buffer(point, 0.008, { units: 'kilometers' });
        lineOriginalPoligon.properties = { color: colors[el.poligonElementType], data: el };
        return [...obj, lineItemElementType, lineOriginalPoligon];
      }, []);
      // get list of 14 zoom's squares
      const arrayOfBigSquares = gHelper.getBigSquares([json.gcsCenter.x - 3, json.gcsCenter.x + 3], [json.gcsCenter.y - 3, json.gcsCenter.y + 3], 17, 14);
      dispatch(removeFromCache(arrayOfBigSquares)).then(() => {
        const mapBoxState = getState().mapbox.viewport;
        dispatch(downloadTilesLatLon(mapBoxState.latitude, mapBoxState.longitude, mapBoxState.zoom));
      });
      dispatch(setDataContentResult(result));
      dispatch(updateLayerDataContent(locations));

      dispatch(success('Success.'));
    } catch (e) {
      console.log(e);
      dispatch(error(`Failed.${e}`));
    }
  };
}

export function testRandomLocations(json, gcsCenter) {
  return async (dispatch, getState) => {
    try {
      const result = await post('/tiles/getRandomLocations', json, true);
      if (!result) return;
      if (result.error) {
        console.log(error);
        dispatch(error(result.error));
        return;
      }

      const locations = result.reduce((obj, el) => {
        const point = turf.point(el.location);
        const lineItemElementType = turf.buffer(point, 0.011, { units: 'kilometers' });
        lineItemElementType.properties = { color: colors[el.elementType], data: el };
        return [...obj, lineItemElementType];
      }, []);
      // get list of 14 zoom's squares
      const arrayOfBigSquares = gHelper.getBigSquares([gcsCenter.x - 3, gcsCenter.x + 3], [gcsCenter.y - 3, gcsCenter.y + 3], 17, 14);
      dispatch(removeFromCache(arrayOfBigSquares)).then(() => {
        const mapBoxState = getState().mapbox.viewport;
        dispatch(downloadTilesLatLon(mapBoxState.latitude, mapBoxState.longitude, mapBoxState.zoom));
      });
      dispatch(setRandomLocationsResult(result));
      dispatch(updateLayerDataContent(locations));

      dispatch(success('Success.'));
    } catch (e) {
      console.log(e);
      dispatch(error(`Failed.${e}`));
    }
  };
}
export function getGeneratedAreas(zoom = 8) {
  return async (dispatch) => {
    try {
      dispatch(addZoomLoadedAreas(zoom));
      const result = await get('/tiles/generation/areas', { zoom }, true);
      if (!result) return;
      if (result.error) {
        dispatch(error(result.error));
        return;
      }

      // dispatch(generatedAreasLoaded(result));
      dispatch(showGeneratedAreas(result.coordinates));
    } catch (e) {
      console.log(e);
      dispatch(error(`Generation failed.${e}`));
    }
  };
}
