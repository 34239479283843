import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
} from '@material-ui/core';
import { ChangePasswordForm } from '../../components';

const useStyles = makeStyles((theme) => ({

  grid: {
    height: '100%',
  },
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    padding: theme.spacing(4),
  },

  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
}));

export const ChangePasswordContainer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
        spacing={2}
      >
        <ChangePasswordForm />
      </Grid>

    </div>
  );
};

export default connect()(ChangePasswordContainer);
