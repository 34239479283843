import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import {
  SettingsContainer,
  LoginContainer,
  ChangePasswordContainer
} from './containers';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
// import { checkToken } from './services';

// const PublicRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) => (!checkToken() ? (
//       <Component {...props} {...rest} />
//     ) : (
//       <Redirect
//         to={{
//           pathname: '/',
//         }}
//       />
//     ))}
//   />
// );

// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) => (checkToken() ? (
//       <Component {...props} {...rest} />
//     ) : (
//       <Redirect
//         to={{
//           pathname: '/login',
//         }}
//       />
//     ))}
//   />
// );

const Routes = () => (
  <Switch>
    <Route exact path="/">
      <Redirect
        to={{
          pathname: '/settings',
        }}
      />
    </Route>
    <RouteWithLayout
      component={SettingsContainer}
      exact
      privateRoute
      layout={MainLayout}
      path="/settings"
    />
    <RouteWithLayout
      component={LoginContainer}
      exact
      privateRoute={false}
      layout={MinimalLayout}
      path="/login"
    />
    <RouteWithLayout
      component={ChangePasswordContainer}
      exact
      layout={MinimalLayout}
      path="/changePassword"
    />
    {/* <Route exact path="/">
      <Redirect
        to={{
          pathname: '/dashboard',
        }}
      />
    </Route>
    <PrivateRoute component={DashboardContainer} path="/dashboard" />
    <PublicRoute path="/login">
      <LoginContainer />
    </PublicRoute> */}
  </Switch>

);

export default Routes;
