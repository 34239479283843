import axios from 'axios';
import { getAuthHeader } from './auth';
import history from './history';

require('dotenv').config();

const API_URL = process.env.REACT_APP_API_URL;
const apiClient = axios.create({
  baseURL: API_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

const getOptions = (isAuth = true, opt = null) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: '',
    },
  };

  if (isAuth) {
    options.headers.Authorization = getAuthHeader();
  }
  if (opt) {
    Object.keys(opt).forEach((key) => {
      if (opt && Object.prototype.hasOwnProperty.call(opt, key)) {
        options.headers[key] = opt[key];
      }
    });
  }

  return options;
};

export const post = async (endpoint, data, isAuth = true) => {
  try {
    const options = getOptions(isAuth);

    const response = await apiClient.post(endpoint, data, options);

    response.data.status = response.status;

    return response.data;
  } catch (error) {
    if (error && error.response) {
      if (error.response.status && error.response.status === 401 && endpoint !== 'authorization/login') {
        return history.push('/login');
      }

      throw new Error(error.response.data.error);
    }

    throw error;
  }
};

export const put = async (endpoint, data, isAuth = true) => {
  try {
    const options = getOptions(isAuth);
    const response = await apiClient.put(endpoint, data, options);

    return response.data;
  } catch (error) {
    if (error && error.response) {
      if (error.response.status && error.response.status === 401) {
        return history.push('/login');
      }
      throw new Error(error.response.data);
    }

    throw error;
  }
};

export const remove = async (endpoint, data, isAuth = true) => {
  try {
    const options = getOptions(isAuth);
    options.data = data;
    const response = await apiClient.delete(endpoint, options);

    return response.data;
  } catch (error) {
    if (error && error.response) {
      if (error.response.status && error.response.status === 401) {
        return history.push('/login');
      }
      throw new Error(error.response.data);
    }

    throw error;
  }
};

export const get = async (endpoint, data, isAuth = true, headers = null) => {
  try {
    let queryString = '';
    if (data) {
      queryString = '?';
      Object.keys(data).map((key, index, array) => {
        const andSymbmol = index + 1 === array.length ? '' : '&';
        queryString += `${key}=${data[key]}${andSymbmol}`;
        return key;
      });
    }

    const options = getOptions(isAuth, headers);
    const response = await apiClient.get(`${endpoint}${queryString}`, options);

    return response.data;
  } catch (error) {
    if (error && error.response) {
      if (error.response.status && error.response.status === 401) {
        return history.push('/login');
      }

      throw new Error(error.response.data);
    }

    throw error;
  }
};
