import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar, Toolbar, IconButton,
} from '@material-ui/core';
import PermDataSetting from '@material-ui/icons/PermDataSetting';
import SecurityIcon from '@material-ui/icons/Security';
import InputIcon from '@material-ui/icons/Input';

import { logout as logoutAction } from '../../../../store/auth/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.white,
  },
  logo: {
    width: '128px',
    height: '28px',
  },
  // signOutButton
}));

const Topbar = (props) => {
  const {
    className, onSidebarOpen, dispatch, ...rest
  } = props;

  const classes = useStyles();
  const logout = () => {
    dispatch(logoutAction());
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            className={classes.logo}
            src="/images/logo.png"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <RouterLink to="/changePassword" className={classes.signOutButton} style={{ padding: 12 }}>
          <SecurityIcon />
        </RouterLink>
        <IconButton
          color="inherit"
          onClick={onSidebarOpen}
        >
          <PermDataSetting />
        </IconButton>
        <IconButton
          className={classes.signOutButton}
          color="inherit"
          onClick={logout}
        >
          <InputIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

Topbar.defaultProps = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(Topbar);
