import {
  LOGIN_USER,
  LOGOUT_USER,
  SHOW_SPINNER,
  SAVE_QR,
  SAVE_USERNAME,
  SAVE_PASSWORD,
  SAVE_CODE,
  SAVE_ACTION,
  SET_CUSTOM_TOKEN,
} from './types';

const initialState = {
  username: null,
  password: null,
  error: null,
  showSpinner: false,
  qrCode: null,
  code: null,
  action: 'login',
};

export default function authReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case SET_CUSTOM_TOKEN:
      return {
        ...state,
        customToken: action.payload.customToken,
      };
    case LOGIN_USER:
      return {
        ...state,
        username: action.payload.username,
        password: null,
        code: null,
        error: action.payload.error,
        showSpinner: false,
        qrCode: null,
        action: 'login',
      };
    case SHOW_SPINNER:
      return {
        ...state,
        showSpinner: action.payload,
      };
    case SAVE_QR:
      return {
        ...state,
        qrCode: action.payload,
      };
    case SAVE_USERNAME:
      return {
        ...state,
        username: action.payload,
      };
    case SAVE_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case SAVE_CODE:
      return {
        ...state,
        code: action.payload,
      };
    case SAVE_ACTION:
      return {
        ...state,
        action: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        username: null,
        password: null,
        error: null,
        showSpinner: false,
        qrCode: null,
        code: null,
        action: 'login',
      };
    default:
      return state;
  }
}
