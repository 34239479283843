import { SET_USER_DATA, SET_CURRENT_USER_DATA, DELETE_CURRENT_USER_DATA } from './types';

export function setCurrentUserData(userData) {
  return {
    type: SET_CURRENT_USER_DATA,
    payload: userData,
  };
}

export function deleteCurrentUserData() {
  return {
    type: DELETE_CURRENT_USER_DATA,
  };
}

export function setUserData(userData) {
  return {
    type: SET_USER_DATA,
    payload: userData,
  };
}
