import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { deleteAlert } from '../../store/alerts/actions';

const mapStateToProps = (state) => ({
  alerts: state.alerts,
});

const Alert = ({ alerts }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  React.useEffect(() => {
    alerts.list.forEach(({ message, type, key }) => {
      enqueueSnackbar(message, { variant: type, autoHideDuration: 2000, anchorOrigin: { horizontal: 'center', vertical: 'top' } });
      dispatch(deleteAlert(key));
    });
  }, [alerts, enqueueSnackbar, dispatch]);

  return null;
};

Alert.propTypes = {
  alerts: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(
  mapStateToProps,
)(Alert);
