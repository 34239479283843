/* eslint-disable no-nested-ternary */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkToken, getToken } from '../../services';

const RouteWithLayout = (props) => {
  const {
    layout: Layout, component: Component, privateRoute = true, path, ...rest
  } = props;

  const userGroups = getToken('groups') ? JSON.parse(getToken('groups')) : [];

  const isLoggedIn = privateRoute ? !!checkToken() : true;
  return (
    <Route
      {...rest}

      render={(matchProps) => (isLoggedIn
        ? (userGroups.includes('Users') && (path.includes('/export') || path.includes('/reports')))
          ? (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          )
          : (
            <Layout>
              <Component {...matchProps} />
            </Layout>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
        )
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  auth: PropTypes.bool,
};

export default RouteWithLayout;
