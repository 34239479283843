import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import jwt from 'jsonwebtoken';
import { composeWithDevTools } from 'redux-devtools-extension';

import alertReducer from './alerts/reducers';
import settingsReducer from './settings/reducers';
import generateReducer from './generate/reducers';
import mapboxReducer from './mapbox/reducers';
import userReducer from './user/reducers';
import authReducer from './auth/reducers';
import {
  history,
} from '../services';

const rootReducer = combineReducers({
  alerts: alertReducer,
  settings: settingsReducer,
  generate: generateReducer,
  mapbox: mapboxReducer,
  user: userReducer,
  auth: authReducer,
});

export const tokenMiddleware = () => (next) => (action) => {
  const token = localStorage.getItem('accessToken');
  if (token && jwt.decode(token).exp < (Date.now() / 1000)) {
    localStorage.clear();
    history.push('login');
    next(action);
  }
  return next(action);
};

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares, tokenMiddleware);

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer),
  );

  return store;
}
