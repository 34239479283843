import React, { useEffect, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ReactJson from 'react-json-view';
import { testTilesContentValue, resetSelectionAria } from '../../../store/generate/actions';
import {
  onSaveTileDetails, setTileDetails, setViewport, clearExpiredContent,
} from '../../../store/mapbox/actions';
import Search from '../Search/Search';
import TilesGeneration from '../TilesGeneration/TilesGeneration';
import TilesInfo from '../TilesInfo/TilesInfo';
import RandomLocations from '../RandomLocations/RandomLocations';
import LocationInfo from '../LocationInfo/LocationInfo';
import {
  validateX, validateY, tile2lat, tile2long,
} from '../../../helpers/gHelper';
import * as constants from '../../../constants';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  tabsroot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  txTilesGeneration: {
    marginTop: 5,
    marginBottom: 5,
  },
  fullWidth: {
    width: '100%',
  },
  panelTab: {
    padding: 0,
    '& .MuiBox-root': {
      padding: '0',
    },
  },
  emptyTileDetails: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '500',

  },
}));

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      style={{ flex: 272 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
const defaultTilesContentValue = {
  groups: [{
    key: 'character',
    count: 1,
  },
  {
    key: 'items',
    count: 3,
  },
  {
    key: 'pets',
    count: 1,
  },
  ],
  tiles: [{ x: 74626, y: 37941, level: 1 }, { x: 74627, y: 37941, level: 2 }],
  gcsCenter: { x: 74626, y: 37941 },
};

const keys = [{ id: 'items', name: 'items' }, { id: 'character', name: 'character' }, { id: 'pets', name: 'pets' }];
const squareSizeOptions = [1, 3, 5, 7];
const levels = [1, 2, 3];

const SidebarTools = (props) => {
  const {
    selectedPlaceOnMap, tileDetails, contentResult, contentLocationSelected, open, variant, onClose, className, contentStatus,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [contentJSON, setContentJSON] = React.useState(0);
  const [valueTilesContent, setTilesContentValue] = React.useState(defaultTilesContentValue);
  const [stateTilesContent, setStateTilesContent] = React.useState(
    {
      groups: keys.reduce((obj, item) => {
        const objCopy = { ...obj };
        objCopy[item.id] = { count: 1 };
        return objCopy;
      }, {}),
      level: 1,
      gcsCenter: { x: 74626, y: 37941 },
      squareSize: 3,
    },
  );

  const buildTilesContentJson = useCallback(() => {
    let contentTilesArray = [];
    for (let x = stateTilesContent.gcsCenter.x - ((stateTilesContent.squareSize - 1) / 2); x <= ((stateTilesContent.squareSize - 1) / 2) + stateTilesContent.gcsCenter.x; x += 1) {
      for (let y = stateTilesContent.gcsCenter.y - ((stateTilesContent.squareSize - 1) / 2); y <= ((stateTilesContent.squareSize - 1) / 2) + stateTilesContent.gcsCenter.y; y += 1) {
        contentTilesArray = [...contentTilesArray, { x, y, level: stateTilesContent.level }];
      }
    }
    const defaultTilesContentValueT = {
      ...defaultTilesContentValue,
      groups: Object.keys(stateTilesContent.groups).filter((keySTC) => stateTilesContent.groups[keySTC].count > 0).map((keySTC) => ({ key: keySTC, count: stateTilesContent.groups[keySTC].count })),
      tiles: contentTilesArray,
      gcsCenter: stateTilesContent.gcsCenter,
    };
    setTilesContentValue(defaultTilesContentValueT);
  }, [stateTilesContent]);

  useEffect(() => {
    if (selectedPlaceOnMap) setStateTilesContent((prevStateTilesContent) => ({ ...prevStateTilesContent, gcsCenter: selectedPlaceOnMap.gcs }));
  }, [selectedPlaceOnMap]);

  useEffect(() => {
    buildTilesContentJson();
  }, [stateTilesContent, buildTilesContentJson]);

  const handleChangeTab = (event, newValue) => {
    if (newValue !== 0) {
      dispatch(resetSelectionAria());
    }
    setContentJSON(newValue);
  };

  const onTestTilesContentValue = () => {
    dispatch(testTilesContentValue(valueTilesContent));
  };

  const onClearExpiredContent = () => {
    dispatch(clearExpiredContent());
  };

  const onTileTypeChange = (event) => {
    const tileStateNew = { ...tileDetails, main: event.target.value };
    dispatch(setTileDetails(tileStateNew));
  };

  const onLevelChange = (event) => {
    const tileStateNew = { ...tileDetails, level: event.target.value };
    dispatch(setTileDetails(tileStateNew));
  };

  const handleChangeSquareSize = (e) => {
    setStateTilesContent({ ...stateTilesContent, squareSize: e.target.value });
  };

  const handleChangeLevel = (e) => {
    setStateTilesContent({ ...stateTilesContent, level: e.target.value });
  };

  const handleChangeCenterCoords = (e, type) => {
    switch (type) {
      case 'x':
        setStateTilesContent({
          ...stateTilesContent,
          gcsCenter: {
            ...stateTilesContent.gcsCenter,
            x: parseInt(e.target.value, 10) > 0 ? parseInt(e.target.value, 10) : 0,
          },
        });
        break;
      case 'y':
        setStateTilesContent({
          ...stateTilesContent,
          gcsCenter: {
            ...stateTilesContent.gcsCenter,
            y: parseInt(e.target.value, 10) > 0 ? parseInt(e.target.value, 10) : 0,
          },
        });
        break;
      default:
        break;
    }
  };

  const handleChangeTilesContent = (e, key, type) => {
    const stateTilesContentCopy = { ...stateTilesContent };

    switch (type) {
      case 'count':

        stateTilesContentCopy.groups[key] = {
          ...stateTilesContentCopy.groups[key],
          count: e.currentTarget.value > 0 ? parseInt(e.currentTarget.value, 10) : 0,
        };
        setStateTilesContent(stateTilesContentCopy);

        break;
      default:
        break;
    }
  };
  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        className={clsx(classes.root, className)}
      >
        <div className={classes.tabsroot} style={{ width: 700 }}>
          <Tabs
            style={{ flex: 84 }}
            orientation="vertical"
            variant="scrollable"
            value={contentJSON}
            onChange={handleChangeTab}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            <Tab label="Search" {...a11yProps(0)} />
            <Tab label="Tiles Generation" {...a11yProps(1)} />
            <Tab label="tiles/content" {...a11yProps(2)} />
            <Tab label="tiles/info" {...a11yProps(3)} />
            <Tab label="Tile details" {...a11yProps(4)} />
            <Tab label="Location details" {...a11yProps(5)} />
            <Tab label="Random locations" {...a11yProps(6)} />
          </Tabs>
          <TabPanel value={contentJSON} index={0} className={classes.panelTab}>
            <Search />
          </TabPanel>
          <TabPanel value={contentJSON} index={1}>
            <TilesGeneration />
          </TabPanel>
          <TabPanel value={contentJSON} index={2}>
            <Grid container>
              <Grid item xs={6} className={classes.txTilesGeneration} style={{ paddingRight: 2 }}>
                <TextField
                  style={{ width: '100%' }}
                  id="coords-x"
                  label="X center"
                  type="number"
                  value={stateTilesContent.gcsCenter.x}
                  onChange={(e) => { handleChangeCenterCoords(e, 'x'); }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} className={classes.txTilesGeneration} style={{ paddingLeft: 2 }}>
                <TextField
                  style={{ width: '100%' }}
                  id="coords-y"
                  label="Y center"
                  type="number"
                  className={classes.fullWidth}
                  value={stateTilesContent.gcsCenter.y}
                  onChange={(e) => { handleChangeCenterCoords(e, 'y'); }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} className={classes.txTilesGeneration} style={{ paddingRight: 2 }}>
                <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                  <InputLabel>Level</InputLabel>
                  <Select
                    id="select-level"
                    value={stateTilesContent.level}
                    onChange={handleChangeLevel}
                    label="Level"
                  >
                    {levels.map((level) => (<MenuItem key={level} value={level}>{level}</MenuItem>)) }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} className={classes.txTilesGeneration} style={{ paddingLeft: 2 }}>
                <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                  <InputLabel>Square Size</InputLabel>
                  <Select
                    id="select-square-size"
                    value={stateTilesContent.squareSize}
                    onChange={handleChangeSquareSize}
                    label="Square Size"
                  >
                    {squareSizeOptions.map((number) => (<MenuItem key={number} value={number}>{number}</MenuItem>)) }

                  </Select>
                </FormControl>
              </Grid>
              {keys.map((el, index) => (
                <Grid key={`grid-standard-number-${el.id}`} item xs={4} className={classes.txTilesGeneration} style={{ paddingRight: index !== keys.length - 1 ? 4 : 0 }}>
                  <TextField
                    style={{ width: '100%' }}
                    id={`standard-number-${el.id}`}
                    label={el.name}
                    type="number"
                    value={stateTilesContent.groups[el.id].count}
                    onChange={(e) => { handleChangeTilesContent(e, el.id, 'count'); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
              ))}
            </Grid>
            <ReactJson
              style={{
                border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: 4, padding: 4, marginTop: 5, marginBottom: 5,
              }}
              src={valueTilesContent}
              iconStyle="square"
              displayDataTypes={false}
              displayObjectSize={false}
              collapsed="1"
              onChange={(e) => { handleChangeTilesContent(e); }}
            />
            <Button
              variant="contained"
              className={classes.txTilesGeneration}
              style={{ width: '100%' }}
              color="primary"
              onClick={onTestTilesContentValue}
              disabled={valueTilesContent === '' || !validateX(stateTilesContent.gcsCenter.x) || !validateY(stateTilesContent.gcsCenter.y) ||
              !Object.keys(stateTilesContent.groups).reduce((accum, key) => accum + stateTilesContent.groups[key].count, 0) > 0}
            >
              Test
            </Button>
            <Button
              variant="contained"
              className={classes.txTilesGeneration}
              style={{ width: '100%' }}
              color="primary"
              onClick={onClearExpiredContent}
            >
              Clear expired content
            </Button>
            {contentResult
            && (
            <>
              <p>
                Status code:
                {' '}
                {contentStatus}
              </p>
              <p>Result:</p>
              <ReactJson
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: 4, padding: 4, marginTop: 5, marginBottom: 5,
                }}
                src={contentResult}
                iconStyle="square"
                displayDataTypes={false}
                displayObjectSize={false}
                collapsed="1"
              />
            </>
            )}
            {contentLocationSelected && (
            <>
              <p>Selected:</p>
              <ReactJson
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: 4, padding: 4, marginTop: 5, marginBottom: 5,
                }}
                src={contentLocationSelected}
                iconStyle="square"
                displayDataTypes={false}
                displayObjectSize={false}
                collapsed="1"
              />
            </>
            )}

          </TabPanel>
          <TabPanel value={contentJSON} index={3}>
            <TilesInfo />
          </TabPanel>
          <TabPanel value={contentJSON} index={4}>
            {tileDetails
              ? (
                <>
                  {tileDetails.prInfo !== undefined ? (
                    <div className={classes.popUpContent}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <p style={{ width: 100 }}>
                            <strong>main:</strong>
                            <br />
                            <select value={tileDetails.main} onChange={onTileTypeChange}>
                              {constants.elementTypeOptions.map((el) => (<option key={el.id} value={el.id}>{el.name}</option>))}
                            </select>
                          </p>

                          <p style={{ width: 100 }}>
                            <strong>level:</strong>
                            <br />
                            <select value={tileDetails.level} onChange={onLevelChange}>
                              {constants.levelOptions.map((el) => (<option key={el.id} value={el.id}>{el.name}</option>))}
                            </select>
                          </p>
                          <p style={{ width: 100 }}>
                            <strong>x: </strong>
                            {tileDetails.gcs.x}
                          </p>
                          <p style={{ width: 100 }}>
                            <strong>y: </strong>
                            {tileDetails.gcs.y}
                          </p>
                          <Button variant="contained" color="primary" onClick={() => { dispatch(onSaveTileDetails(tileDetails)); }} disabled={tileDetails.main === tileDetails.original.main && tileDetails.level === tileDetails.original.level}>
                            Save
                          </Button>
                          <hr />
                          <br />
                          Additional info:
                          <table style={{ height: 'auto', width: 250 }}>
                            <tbody>
                              <tr style={{ backgroundColor: 'grey', fontWeight: 'bold' }}>
                                <td style={{ width: 48 }}>layer</td>
                                <td style={{ width: 86 }}>class</td>
                                <td style={{ width: 80 }}>square, m2</td>
                              </tr>
                              {tileDetails.additionalSquarePoligons.map((el) => (
                                <tr key={`${el.layer}-${el.class}`}>
                                  <td style={{ width: 48 }}>
                                    {el.layer}
                                  </td>
                                  <td style={{ width: 86 }}>
                                    {el.class}
                                  </td>
                                  <td style={{ width: 80 }}>
                                    {Math.round(el.square)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Grid>
                        <Grid item xs={6}>
                          {tileDetails.prInfo.map((proportionInfo) => (
                            <div key={`proportionInfo-${proportionInfo.elementKey}`}>
                              <p>
                                <strong>Element name:&nbsp;&nbsp;</strong>
                                {' '}
                                {proportionInfo.elementKey}
                              </p>
                              <p>
                                <strong>Element sq, m2:&nbsp;</strong>
                                {Math.round(proportionInfo.square)}
                              </p>
                              <p>
                                <strong>Element sq, %:&nbsp;&nbsp;</strong>
                                {Math.round(proportionInfo.percent * 1000) / 10}
                              </p>
                              <table style={{ height: 'auto', width: 250 }}>
                                <tbody>
                                  <tr style={{ backgroundColor: 'grey', fontWeight: 'bold' }}>
                                    <td style={{ width: 48 }}>layer</td>
                                    <td style={{ width: 86 }}>class</td>
                                    <td style={{ width: 80 }}>square, m2</td>
                                  </tr>
                                  {proportionInfo.info.calcDetails.map((el) => (
                                    <tr key={`${el.layer}-${el.class}`}>
                                      <td style={{ width: 48 }}>
                                        {el.layer}
                                      </td>
                                      <td style={{ width: 86 }}>
                                        {el.class}
                                      </td>
                                      <td style={{ width: 80 }}>
                                        {Math.round(el.square)}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          ))}
                        </Grid>
                      </Grid>

                      <hr style={{ marginTop: 10 }} />

                    </div>
                  ) : (
                    <div className={classes.popUpContent}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <p style={{ width: 100 }}>
                            <strong>main:</strong>
                            <Select
                              value={constants.elementTypeOptions.find((option) => option.id === tileDetails.main)}
                              getOptionValue={(option) => option.id}
                              getOptionLabel={(option) => option.name}
                              options={constants.elementTypeOptions}
                              onChange={onTileTypeChange}
                            />
                          </p>

                          <p style={{ width: 100 }}>
                            <strong>level:</strong>
                            <Select
                              value={constants.levelOptions.find((option) => option.id === tileDetails.level)}
                              getOptionValue={(option) => option.id}
                              getOptionLabel={(option) => option.name}
                              options={constants.levelOptions}
                              onChange={onLevelChange}
                            />
                          </p>
                          <p style={{ width: 100 }}>
                            <strong>x: </strong>
                            {tileDetails.gcs.x}
                          </p>
                          <p style={{ width: 100 }}>
                            <strong>y: </strong>
                            {tileDetails.gcs.y}
                          </p>
                          <Button variant="contained" color="primary" onClick={() => { dispatch(onSaveTileDetails(tileDetails)); }} disabled={tileDetails.main === tileDetails.original.main && tileDetails.level === tileDetails.original.level}>
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </>
              )
              : (
                <Grid item xs={12}>
                  <Typography className={classes.emptyTileDetails}>Choose the generated tile to see the tile info</Typography>
                </Grid>

              ) }
          </TabPanel>
          <TabPanel value={contentJSON} index={5}>
            <LocationInfo />
          </TabPanel>
          <TabPanel value={contentJSON} index={6}>
            <RandomLocations />
          </TabPanel>
        </div>
      </div>
    </Drawer>
  );
};

SidebarTools.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  contentResult: PropTypes.arrayOf(PropTypes.shape()),
  contentLocationSelected: PropTypes.shape(),
  tileDetails: PropTypes.shape(),
  selectedPlaceOnMap: PropTypes.shape(),
  contentStatus: PropTypes.number,
};

SidebarTools.defaultProps = {
  className: '',
  onClose: null,
  contentResult: undefined,
  contentLocationSelected: undefined,
  tileDetails: undefined,
  selectedPlaceOnMap: undefined,
  contentStatus: 200,
};

const mapStateToProps = (state) => ({
  tileDetails: state.mapbox.tileDetails,
  contentResult: state.mapbox.contentResult,
  contentStatus: state.mapbox.contentStatus,
  contentLocationSelected: state.mapbox.contentLocationSelected,
  selectedPlaceOnMap: state.mapbox.selectedPlaceOnMap,
});

export default connect(mapStateToProps)(SidebarTools);
